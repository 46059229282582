import { get, post, remove } from './../fetch-interceptor';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { push, RouterAction } from 'react-router-redux';
import { IEntityStore, StoreHelper, partialUpdate, IDeletionResult, addOrUpdateOrRemove, IArchivingResult } from './services/storeHelper';
import {
    Dictionary, IBlinePlanActual, ICalculation, ISourceInfo, IConnected, EntityType, IWithWarnings,
    Impact, IBaseEntity, IWithChangeHistory, IWithInsights, UpdateContext, IInsightsData, IEntityInfo, IWithKeyResultAssignments,
    ppmxTaskConnectionId, KeyResultAssignment, IWarning, IEditable, IWithImage, IPatch, IWithLayout, IWithSourceInfos, IWithAiInsights, IWithAttributes,
    ProgressCalculationType, IWithResourcePlan, IWithName, IWithManager, IWithStartFinishDates, IWithBenefits
} from "../entities/common";
import {
    KeyDate, Risk, Issue, Iteration, LessonLearned, ActionItem, KeyDecision, SteeringCommittee, ChangeRequest,
    KeyDateWithWarnings, ITask, Dependency, PurchaseOrder, Invoice, Deliverable, DeliverableWithWarnings,
    urlParamsBuilder
} from "../entities/Subentities";
import * as Metadata from "../entities/Metadata";
import { MetadataService, UpdateUIControlInfo, ActionsBuilder, namesof } from './services/metadataService';
import * as ExternalEpmConnectStore from "./ExternalEpmConnectStore";
import { defaultCatch } from "./utils";
import * as NotificationsStore from "./NotificationsStore";
import { ILinkDto } from './integration/common';
import { IPlanInfo } from './integration/Office365Store';
import { IJiraLinkInfo } from './integration/JiraStore';
import { ISpoProject } from './integration/SpoStore';
import { IMondayComBaseSourceData } from './integration/MondayComStore';
import { IWithPrioritiesAlignment } from './StrategicPrioritiesListStore';
import { ImportExportFactory } from './importExport';
import { TasksOperationsFactory, ITasksState } from './tasks';
import { TeamsChannelLink } from '../components/integration/TeamsChannelConnectControl';
import { ApplyLayout, LayoutApplied } from './layouts';
import { Objective, ObjectivesLoadedAction } from './ObjectivesListStore';
import { ChangeHistoryOperationsFactory, HISTORY_DEFAULT_STATE, IChangeHistoryState } from './HistoryListStore';
import { PpmxTimeLinkInfo } from '../components/integration/PpmxTimeConnectControl';
import { GroupsOperationsFactory, GroupsState } from './groups';
import { actionsForBuilder, BaseSubentitiesUpdateAction, ICreationData } from './Subentity';
import { ISmartsheetProjectSourceData } from './integration/SmartsheetStore';
import { SourceType } from './ExternalEpmConnectStore';
import { IVSTSLinkData } from '../components/integration/Vsts/VSTSConnectControl';
import { IP4WProject } from './integration/P4WStore';
import { ResourcePlanOperationsFactory } from './ResourcePlanListStore';
import { waitForFinalEvent } from '../components/utils/common';

const namespace = 'PROJECT';
const { importExportActionCreators, importExportReducer } = ImportExportFactory<string, ProjectInfo, ProjectsListState>(namespace, EntityType.Project);
const tasksStore = TasksOperationsFactory<string, ProjectInfo, ProjectsListState>(namespace, EntityType.Project);
const historyStore = ChangeHistoryOperationsFactory<ProjectInfo, ProjectsListState>(EntityType.Project);
const groupsStore = GroupsOperationsFactory("externaltask/group", EntityType.Project);
const resourcePlanStore = ResourcePlanOperationsFactory<ProjectInfo, ProjectsListState>(EntityType.Project);

export const statuses = ["Overall", "Risks", "Issues", "Resources", "Cost", "Schedule"];
export const StatusNames = ["OverallStatus", "ScheduleStatus", "CostStatus", "ResourcesStatus", "RisksStatus", "IssuesStatus"];

export interface ProjectCalculation<TScheduleData = ScheduleData> extends ICalculation {
    work: Dictionary<IBlinePlanActual>;
    taskProgresses: Dictionary<TaskProgressData>;
    teamMembersData: Dictionary<TeamMemberData>;
    scheduleData: Dictionary<TScheduleData>;
    iterationsData: Dictionary<IterationsData>;
    timeData: Dictionary<TimeData>;
}

export type IterationsData = {
    columns: Column[];
}

export type TaskProgressData = {
    total: number;
    completed: number;
    active: number;
    pctComplete: number | null;
};

export type TeamMemberData = {
    columns: Column[];
    data: Row[];
};

export const getColumnKey = (column: Column) => column.name.replace(/[^a-zA-Z0-9]/g, '_');

export type Column = { name: string, label: string, visible: boolean, settings: Dictionary<any> };
export type Row = { name: string, value: any }[];

export type TasksStatuses = Record<string, number>;

export interface ScheduleData {
    tasksStatuses: TasksStatuses;
    tasksSummary: TasksSummary;
    tasksProgress: TasksProgress;
    myAssignments: MyAssignments;
    counters: ScheduleCounter;
}

export interface TasksSummary {
    today: number;
    thisWeek: number;
    noDates: number;
    noAssignments: number;
}

export interface TasksProgress extends ProgressTasksCalculation {
    late: number;
    all: number;
}

interface ProgressTasksCalculation {
    notStarted: number;
    inProgress: number;
    completed: number;
}

export interface MyAssignments {
    notStarted: number;
    thisWeek: number;
    late: number;
    active: number;
}

type ScheduleCounter = {
    total: { statistics: Dictionary<StatisticCounter> },
    iteration: { label: string, statistics: Dictionary<StatisticCounter> },
}

export type StatisticCounter = {
    completed: number;
    open: number;
}

export type TimeData = {
    columns: Column[];
    data: Row[];
};

export enum Progress {
    NotStarted = 0,
    Completed = 1,
    InProgress = 2,
    OnHold = 3,
    Canceled = 4
}

export const ProgressDescription = new Map<Progress, string>([
    [Progress.NotStarted, 'Not started'],
    [Progress.Completed, 'Completed'],
    [Progress.InProgress, 'In progress'],
    [Progress.OnHold, 'On hold']
]);

export interface ProjectAttrs extends IWithName, IWithManager, IWithStartFinishDates, IWithBenefits {
    Id: string;
    Identifier: string;
    Number: number;
    OverallStatus: string;
    Score: number;
    Progress: Progress;
    HighLevelScope: string;
    Priority: Impact;
    Budget: number;
    Stage: string;
    Portfolio: IPortfolioInfo[];
    Program: IProgramInfo[];
    ParentIdea: IIdeaInfo | null;
    CreatedDate: string;
    PlannedWork: number;
    ActualWork: number;
    EstimatedCost: number;
    EstimatedCharge: number;
    PlannedResourcesCount: number;
    CommittedResourcesCount: number;
    Tags?: string[];
    Description?: string;
    BaselineStartDate?: string;
    BaselineFinishDate?: string;
    BaselineSetDate?: string;
    StartDateVariance?: number;
    FinishDateVariance: number;
}

type ProjectAttributesModel = IBaseEntity & IWithWarnings & IWithChangeHistory & IWithInsights & {
    attributes: ProjectAttrs;
    keyDates: KeyDate[];
    deliverables: Deliverable[];
}

export interface IPortfolioInfo extends IEntityInfo { }
export interface IIdeaInfo extends IEntityInfo { }
export interface IProjectInfo extends IEntityInfo { }
export interface IProgramInfo extends IEntityInfo { }
export interface IPortfolioViaProgram {
    portfolio: IPortfolioInfo;
    programId: string;
}

export interface SyncableProjectInfo {
    id: string;
    sourceInfos: ISourceInfo[];
    isSyncable: boolean;
}

export interface ProjectSettings {
    primarySchedule: SourceType;
    progressCalculationType: ProgressCalculationType;
    isTaskAutoCalculationMode: boolean;
    calculateCompletedWorkBasedOnReportedTime: boolean;
}

export interface ISetAsPrimary {
    setAsPrimary: boolean;
}

export interface ProjectInfo<TScheduleData = ScheduleData>
    extends SyncableProjectInfo,
        IWithWarnings,
        IWithPrioritiesAlignment,
        IWithResourcePlan,
        IWithChangeHistory,
        IWithInsights,
        IWithKeyResultAssignments,
        IWithLayout,
        IWithImage,
        IEditable,
        Metadata.IWithSections,
        Metadata.IWithPinnedViews,
        IWithSourceInfos,
        IWithAttributes,
        IWithAiInsights {
    keyDates: KeyDate[];
    risks: Risk[];
    issues: Issue[];
    resourceIds: string[];
    lessonsLearned: LessonLearned[];
    iterations: Iteration[];
    actionItems: ActionItem[];
    keyDecisions: KeyDecision[];
    steeringCommittee: SteeringCommittee[];
    purchaseOrders: PurchaseOrder[];
    invoices: Invoice[],
    deliverables: Deliverable[];
    changeRequests: ChangeRequest[];
    dependencies: Dependency[];
    tasks?: ITask[];
    calculation: ProjectCalculation<TScheduleData>;
    attributes: ProjectAttrs & Dictionary<any>;
    canCollaborate: boolean;
    canConfigure: boolean;
    isFavorite: boolean;
    lastModifiedDate: string;
    appliedLayout?: string;
    sectionsTemp?: Metadata.Section[];
    portfolioViaProgram: IPortfolioViaProgram[];
    externalIcons: Dictionary<Dictionary<string>>;
    isArchived?: boolean;
    isPrivate?: boolean;
    settings: ProjectSettings;
    archivedDate?: string;
    description: string;
}

export function getProjectTasksPath(project: { id: string, isArchived?: boolean }, connectionId?: string) {
    const url = `${getProjectPath(project)}/tasks`;

    if (connectionId) {
        const query = new URLSearchParams();
        query.set(urlParamsBuilder.connectionId, connectionId);
        return `${url}?${query.toString()}`;
    }

    return url;
}

export function getProjectPath(project: { id: string, isArchived?: boolean }) {
    return `/${project.isArchived ? "archivedproject" : "project"}/${project.id}`;
}

export enum O365LinkType {
    None = 0x0,
    Group = 0x1,
    Plan = 0x2,
    PlanWithGroup = 0x4
}

export interface ProjectsListState extends IEntityStore<ProjectInfo> {
    isLoading: boolean;
    isListLoading: boolean;
    isTasksLoading: boolean;
    isListUpdating: boolean;
    isUpdatingSections: boolean;
    deletionResult?: IDeletionResult[];
    archivingResult?: IArchivingResult[];

    tasks: ITasksState;
    changeHistory: IChangeHistoryState;
    groups: GroupsState;
}

export const DEFAULT_BULK_EDIT_COLUMNS = namesof<ProjectAttrs>(["Name", 'OverallStatus']);

export interface RequestProjectsAction {
    type: 'REQUEST_PROJECTS';
}

interface ReceivedProjectsAction {
    type: 'RECEIVED_PROJECTS';
    projects: ProjectInfo[];
}
export interface ReceivedProjectsPartAction {
    type: 'RECEIVED_PROJECTS_PART';
    projects: ProjectInfo[];
}

export interface CreateProjectSuccessAction {
    type: 'CREATE_PROJECT_SUCCESS';
    project: ProjectInfo;
    isNotSetActiveEntity?: boolean;
}

export interface BultUpdateProjectSuccessAction {
    type: 'BULK_UPDATE_PROJECT_SUCCESS';
    projects: ProjectInfo[];
}

interface UpdateImage {
    type: 'UPDATE_PROJECT_IMAGE';
    projectId: string;
    imageId?: string;
}

interface LoadProject {
    type: "LOAD_PROJECT";
    id?: string;
}

interface ReceivedDeleteProjectResultAction {
    type: 'RECEIVED_REMOVE_PROJECT_RESULT';
    deletionResult?: IDeletionResult[];
}

interface ReceivedArchiveProjectResultAction {
    type: 'RECEIVED_ARCHIVE_PROJECT_RESULT';
    archivingResult?: IArchivingResult[];
}

interface ReceivedProject {
    type: "RECEIVED_PROJECT";
    project: ProjectInfo;
}

interface ReceivedProjectAttributes {
    type: "RECEIVED_PROJECT_ATTRIBUTES";
    data: ProjectAttributesModel;
}

export interface RemovedProjectsSourceInfosAction {
    type: 'REMOVED_PROJECTS_SOURCE_INFOS';
    connectionId: string;
}

interface ReceivedSourceInfosAction {
    type: 'RECEIVED_PROJECT_SOURCE_INFOS';
    data: SyncableProjectInfo;
}

interface ReceivedProjectsSourceInfosAction {
    type: 'RECEIVED_PROJECTS_SOURCE_INFOS';
    data: SyncableProjectInfo[];
}
interface RequestProjectsSourceInfosAction {
    type: 'REQUEST_PROJECTS_SOURCE_INFOS';
}

interface UpdatingSectionsAction {
    type: 'UPDATING_PROJECT_SECTIONS';
    projectId: string;
}

interface UpdateSectionAction {
    type: 'UPDATE_PROJECT_SECTION_SUCCESS';
    projectId: string;
    sections: Metadata.Section[];
}

interface UpdatePinnedViewsAction {
    type: 'UPDATE_PROJECT_PINNED_VIEWS_SUCCESS';
    projectId: string;
    pinnedViews: string[];
}

interface UpdateUIControlAction {
    type: 'UPDATE_UICONTROL_SUCCESS';
    uiControlInfo: UpdateUIControlInfo;
}

interface UpdateProjectKeyDates extends BaseSubentitiesUpdateAction<KeyDate> {
    type: "UPDATE_PROJECT_KEYDATES";
    warnings: IWarning[];
}

interface UpdateProjectRisks extends BaseSubentitiesUpdateAction<Risk> {
    type: "UPDATE_PROJECT_RISKS";
}

interface UpdateProjectIterations extends BaseSubentitiesUpdateAction<Iteration> {
    type: "UPDATE_PROJECT_ITERATIONS";
}

interface UpdateProjectIssues extends BaseSubentitiesUpdateAction<Issue> {
    type: "UPDATE_PROJECT_ISSUES";
}

interface UpdateProjectLessonsLearned extends BaseSubentitiesUpdateAction<LessonLearned> {
    type: "UPDATE_PROJECT_LESSONSLEARNED";
}

interface UpdateProjectActionItems extends BaseSubentitiesUpdateAction<ActionItem> {
    type: "UPDATE_PROJECT_ACTIONITEMS";
}

interface UpdateProjectKeyDecisions extends BaseSubentitiesUpdateAction<KeyDecision> {
    type: "UPDATE_PROJECT_KEYDECISIONS";
}

interface UpdateProjectSteeringCommittee extends BaseSubentitiesUpdateAction<SteeringCommittee> {
    type: "UPDATE_PROJECT_STEERINGCOMMITTEE";
}

interface UpdateProjectPurchaseOrders extends BaseSubentitiesUpdateAction<PurchaseOrder> {
    type: "UPDATE_PROJECT_PURCHASEORDERS";
}

interface UpdateProjectDeliverables extends BaseSubentitiesUpdateAction<Deliverable> {
    type: "UPDATE_PROJECT_DELIVERABLES";
    warnings?: IWarning[];
}

interface UpdateProjectInvoices extends BaseSubentitiesUpdateAction<Invoice> {
    type: "UPDATE_PROJECT_INVOICES";
}

interface UpdateProjectChangeRequests extends BaseSubentitiesUpdateAction<ChangeRequest> {
    type: "UPDATE_PROJECT_CHANGEREQUESTS";
}

interface UpdateProjectDependencies extends BaseSubentitiesUpdateAction<Dependency> {
    type: "UPDATE_PROJECT_DEPENDENCIES";
}

interface UpdateCalculationAction {
    type: 'UPDATE_PROJECT_CALCULATION';
    projectId: string;
    calculation: ProjectCalculation;
    updates: Dictionary<any>;
}

interface UpdateSettingsAction {
    type: 'UPDATE_PROJECT_SETTINGS';
    projectId: string;
    info: ProjectsSettinsUpdateResult;
}

interface UpdateAiInsightsAction {
    type: 'UPDATE_PROJECT_AIINSIGHTS';
    projectId: string;
    info: UpdateProjectAiInsightInfo;
}

interface UpdatedAiInsightsAction {
    type: 'UPDATED_PROJECT_AIINSIGHTS';
    projectId: string;
    isAiInsightsLoading: boolean;
}

interface SetIsFavoriteAction {
    type: 'SET_PROJECT_IS_FAVORITE';
    projectId: string;
    isFavorite: boolean;
}

type ProjectTasksSummary = {
    scheduleData: IConnected<ScheduleData>;
    teamMembersData: IConnected<TeamMemberData>;
    taskProgressesData: IConnected<TaskProgressData>
}

export type UpdatedTasksCalculation = ProjectTasksSummary & {
    type: 'UPDATED_PROJECT_TASKS_CALCULATION';
    projectId: string;
}

export interface ReceivedKeyResultAssignments {
    type: 'RECEIVED_KEY_RESULT_ASSIGNMENTS';
    projectId: string;
    keyResults: KeyResultAssignment[];
}

interface ProjectsSettinsUpdateResult extends IWithWarnings, IWithInsights {
    keyDates: KeyDate[];
    settings: ProjectSettings;
    taskProgressesData: IConnected<TaskProgressData>;
    attributes: ProjectAttrs;
}

interface UpdateProjectAiInsightInfo extends IWithAiInsights {
    errorMessage?: string;
    infoMessage?: string;
}

type KnownAction = RequestProjectsAction
    | ReceivedProjectsAction
    | ReceivedProjectsPartAction
    | CreateProjectSuccessAction
    | BultUpdateProjectSuccessAction
    | UpdateImage
    | LoadProject
    | ReceivedProject
    | ReceivedProjectAttributes
    | ReceivedSourceInfosAction
    | ReceivedProjectsSourceInfosAction
    | RequestProjectsSourceInfosAction
    | ReceivedDeleteProjectResultAction
    | UpdatingSectionsAction
    | UpdateSectionAction
    | UpdatePinnedViewsAction
    | UpdateUIControlAction
    | UpdateProjectKeyDates
    | UpdateProjectRisks
    | UpdateProjectIssues
    | UpdateProjectLessonsLearned
    | UpdateProjectIterations
    | UpdateProjectActionItems
    | UpdateProjectKeyDecisions
    | UpdateProjectSteeringCommittee
    | UpdateProjectPurchaseOrders
    | UpdateProjectInvoices
    | UpdateProjectDeliverables
    | UpdateProjectChangeRequests
    | UpdateProjectDependencies
    | UpdateCalculationAction
    | SetIsFavoriteAction
    | UpdatedTasksCalculation
    | UpdateSettingsAction
    | UpdateAiInsightsAction
    | UpdatedAiInsightsAction
    | RemovedProjectsSourceInfosAction
    | ReceivedKeyResultAssignments
    | ReceivedArchiveProjectResultAction;

export interface O365GroupLinkInfo {
    groupId?: string;
    groupName?: string;
    addProjectOwnerToGroup?: boolean;
    addEngagementsAsMembers?: boolean;
}

type KeyDateDeletionResult = {
    id: string,
    name: string,
    externalId?: string,
    sourceType?: number,
    keydateIsDeleted: boolean,
    externalTaskIsDeleted: boolean
}

const actionsFor = actionsForBuilder<KnownAction>(EntityType.Project);
const subentitiesActionCreators = {
    removeKeyDates: (projectId: string, keyDateIds: string[]): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        remove<KeyDateWithWarnings & { deletionResult?: KeyDateDeletionResult[] }>(
            `api/project/${projectId}/keyDate`, { ids: keyDateIds })
            .then(data => {
                const deletedIds = data.deletionResult?.filter(_ => _.keydateIsDeleted).map(_ => _.id);
                dispatch({ type: 'UPDATE_PROJECT_KEYDATES', entityId: projectId, remove: deletedIds, warnings: data.warnings });
                defaultActionCreators.refreshProjectTaskCalculation(EntityType.Project, projectId)(dispatch);

                const notDeletedCount = data.deletionResult?.filter(_ => _.sourceType === SourceType.Ppmx && !_.keydateIsDeleted).length;
                if (notDeletedCount) {
                    dispatch(NotificationsStore.actionCreators.pushNotification({
                        type: NotificationsStore.NotificationType.Warn,
                        message: notDeletedCount === 1
                            ? 'Unable to delete the key date since it is promoted from a Summary task. Delete corresponding task to delete the promoted key date.'
                            : 'Unable to delete some of the key dates since they were promoted from Summary Tasks. Delete corresponding tasks to delete the promoted key dates.'
                    }))
                }

                data.deletionResult
                    ?.filter(_ => _.sourceType === SourceType.Ppmx && _.keydateIsDeleted && !_.externalTaskIsDeleted)
                    .forEach(_ =>
                        dispatch(NotificationsStore.actionCreators.pushNotification({
                            message: `Key date '${_.name}' was deleted. Related task was not deleted because key date was imported manually.`
                        }))
                    );
            })
            .catch(defaultCatch(dispatch));
    },
    createKeyDate: (projectId: string, data: ICreationData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/project/${projectId}/keyDate`, data)
            .then(dto => dispatch({ type: 'UPDATE_PROJECT_KEYDATES', entityId: projectId, addOrUpdate: dto.keyDates, warnings: dto.warnings }))
            .catch(defaultCatch(dispatch));
    },
    updateKeyDates: (projectId: string, keyDates: IPatch<KeyDate>[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/project/${projectId}/keyDate/bulk`, keyDates)
            .then(data => dispatch({ type: 'UPDATE_PROJECT_KEYDATES', entityId: projectId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    linkKeyDate: (projectId: string, data: Dictionary<string[]>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/project/${projectId}/keyDate/link`, data)
            .then(dto => dispatch({ type: 'UPDATE_PROJECT_KEYDATES', entityId: projectId, addOrUpdate: dto.keyDates, warnings: dto.warnings }))
            .catch(defaultCatch(dispatch));
    },
    setKeyDatesBaseline: (projectId: string, ids: string[]): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ keyDates: KeyDate[], warnings: IWarning[] }>(`api/project/${projectId}/keyDate/baseline`, { ids })
            .then(data => dispatch({ type: 'UPDATE_PROJECT_KEYDATES', entityId: projectId, addOrUpdate: data.keyDates, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    bulkCreateRisks: (projectId: string, risks: IPatch<Risk>[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Risk[]>(`api/project/${projectId}/risk/bulk/create`, risks)
            .then(data => dispatch({ type: 'UPDATE_PROJECT_RISKS', entityId: projectId, addOrUpdate: data }))
            .catch(defaultCatch(dispatch));
    },
    createDeliverable: (projectId: string, data: ICreationData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ deliverables: Deliverable[], warnings: IWarning[] }>(`api/project/${projectId}/deliverable`, data)
            .then(dto => dispatch({ type: 'UPDATE_PROJECT_DELIVERABLES', entityId: projectId, addOrUpdate: dto.deliverables, warnings: dto.warnings }))
            .catch(defaultCatch(dispatch));
    },
    updateDeliverables: (projectId: string, deliverables: IPatch<Deliverable>[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ deliverables: Deliverable[], warnings: IWarning[] }>(`api/project/${projectId}/deliverable/bulk`, deliverables)
            .then(data => dispatch({ type: 'UPDATE_PROJECT_DELIVERABLES', entityId: projectId, addOrUpdate: data.deliverables, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    linkDeliverable: (projectId: string, data: Dictionary<string[]>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ deliverables: Deliverable[], warnings: IWarning[] }>(`api/project/${projectId}/deliverable/link`, data)
            .then(dto => dispatch({ type: 'UPDATE_PROJECT_DELIVERABLES', entityId: projectId, addOrUpdate: dto.deliverables, warnings: dto.warnings }))
            .catch(defaultCatch(dispatch));
    },
    removeDeliverables: (projectId: string, deliverableIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<DeliverableWithWarnings>(`api/project/${projectId}/deliverable`, { ids: deliverableIds })
            .then(data => dispatch({ type: 'UPDATE_PROJECT_DELIVERABLES', entityId: projectId, remove: deliverableIds, warnings: data.warnings }))
            .catch(defaultCatch(dispatch));
    },
    ...actionsFor<Risk>().create('Risk', _ => ({ type: 'UPDATE_PROJECT_RISKS', ..._ })),
    ...actionsFor<Iteration>().create('Iteration', _ => ({ type: 'UPDATE_PROJECT_ITERATIONS', ..._ })),
    ...actionsFor<Issue>().create('Issue', _ => ({ type: 'UPDATE_PROJECT_ISSUES', ..._ })),
    ...actionsFor<LessonLearned>().create('LessonLearned', _ => ({ type: 'UPDATE_PROJECT_LESSONSLEARNED', ..._ })),
    ...actionsFor<ActionItem>().create('ActionItem', _ => ({ type: 'UPDATE_PROJECT_ACTIONITEMS', ..._ })),
    ...actionsFor<KeyDecision>().create('KeyDecision', _ => ({ type: 'UPDATE_PROJECT_KEYDECISIONS', ..._ })),
    ...actionsFor<SteeringCommittee>().create('SteeringCommittee', _ => ({ type: 'UPDATE_PROJECT_STEERINGCOMMITTEE', ..._ })),
    ...actionsFor<ChangeRequest>().create('ChangeRequest', _ => ({ type: 'UPDATE_PROJECT_CHANGEREQUESTS', ..._ })),
    ...actionsFor<Dependency>().create('Dependency', _ => ({ type: 'UPDATE_PROJECT_DEPENDENCIES', ..._ })),
    ...actionsFor<PurchaseOrder>().create('PurchaseOrder', _ => ({ type: 'UPDATE_PROJECT_PURCHASEORDERS', ..._ })),
    ...actionsFor<Invoice>().create('Invoice', _ => ({ type: 'UPDATE_PROJECT_INVOICES', ..._ })),
}

const queueDictionary: Dictionary<any[]> = {};
const retry = (callback: () => void, key: string) => {
    if(!queueDictionary[key]){
        queueDictionary[key] = []; 
    }
    const queue = queueDictionary[key];
    while (queue.length > 0) {
        const func = queue.pop();
        clearTimeout(func);
    }

    callback();
    const timeouts = [10, 30, 60];
    timeouts.forEach(timeout => {
        queue.push(setTimeout(callback, timeout * 1000));
    }); 
}

const getProjectTaskCalculationImpl = (entityType: EntityType, projectId: string, dispatch: any) => {
    get<ProjectTasksSummary>(`api/${entityType}/${projectId}/task/summary`)
        .then(data =>        
            dispatch({
                type: "UPDATED_PROJECT_TASKS_CALCULATION",
                projectId,
                scheduleData: data.scheduleData,
                teamMembersData: data.teamMembersData,
                taskProgressesData: data.taskProgressesData
            }))
}

const getPrimarySystemSwitchedMessage = (to: SourceType): any => ({ message: `The Primary Schedule has been switched to ${ExternalEpmConnectStore.SourceType_.getName(to)}` });

export const defaultActionCreators = {
    loadProject: (projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<ProjectInfo>(`api/project/${projectId}`)
            .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
            .catch(defaultCatch(dispatch)); // Ensure server-side prerendering waits for this to complete
        dispatch({ type: 'LOAD_PROJECT', id: projectId });
    },
    refreshProjectTaskCalculation: (entityType: EntityType, projectId: string) => {
        const key = `api/${entityType}/${projectId}/task/summary`;
        return waitForFinalEvent((dispatch: any) => retry(() => getProjectTaskCalculationImpl(entityType, projectId, dispatch), key), 500, key );
    },
    refreshProjectAttributes: (projectId: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            get<ProjectAttributesModel>(`api/project/${projectId}/attributes`)
                .then(data => dispatch({ type: 'RECEIVED_PROJECT_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    updateProjectAttributes: (projectId: string, updates: Dictionary<any>, context?: Dictionary<UpdateContext>):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<ProjectAttributesModel>(`api/project/${projectId}/attributes`, { updates, context })
                .then(data => dispatch({ type: 'RECEIVED_PROJECT_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    resetProjectStatus: (projectId: string, statusAttributeName: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<ProjectAttributesModel>(`api/project/${projectId}/resetStatus/${statusAttributeName}`, {})
                .then(data => dispatch({ type: 'RECEIVED_PROJECT_ATTRIBUTES', data }))
                .catch(defaultCatch(dispatch));
        },
    updateSettings: (projectId: string, data: Partial<IInsightsData & ProjectSettings>, cb?: () => void):
        AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
            post<ProjectsSettinsUpdateResult>(`api/project/${projectId}/settings`, data)
                .then(info => {
                    dispatch({ type: 'UPDATE_PROJECT_SETTINGS', projectId, info });
                    defaultActionCreators.refreshProjectTaskCalculation(EntityType.Project, projectId)(dispatch);
                    cb?.();
                })
                .catch(defaultCatch(dispatch));
        },
    updateAiInsights: (projectId: string):
        AppThunkAction<UpdateAiInsightsAction | UpdatedAiInsightsAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<UpdateProjectAiInsightInfo>(`api/ai/project/${projectId}/insights`, {})
                .then(info => {
                    if (info.errorMessage) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: info.errorMessage, type: NotificationsStore.NotificationType.Error }));
                    }
                    dispatch({ type: 'UPDATE_PROJECT_AIINSIGHTS', projectId, info });
                    dispatch({ type: 'UPDATED_PROJECT_AIINSIGHTS', projectId, isAiInsightsLoading: false });
                })
                .catch(ex => {
                    dispatch({ type: 'UPDATED_PROJECT_AIINSIGHTS', projectId, isAiInsightsLoading: false });
                    dispatch(NotificationsStore.actionCreators.pushNotification({ message: ex?.response?.data?.errorMessage, type: NotificationsStore.NotificationType.Error }));
                });
            dispatch({ type: 'UPDATED_PROJECT_AIINSIGHTS', projectId, isAiInsightsLoading: true });
        },
    updateSections: ActionsBuilder.buildEntityUpdateSections(`api/project`,
        (projectId, sections, dispatch) => dispatch({
            type: 'UPDATE_PROJECT_SECTION_SUCCESS',
            projectId,
            sections
        })),
    updateSectionsOnClient: ActionsBuilder.buildEntityUpdateSectionsOnClient((projectId, sections, dispatch) => dispatch({
        type: 'UPDATE_PROJECT_SECTION_SUCCESS',
        projectId,
        sections
    })),
    updatePinnedViews: ActionsBuilder.buildEntityUpdatePinnedViews(`api/project`,
        (projectId, pinnedViews, dispatch) => dispatch({
            type: 'UPDATE_PROJECT_PINNED_VIEWS_SUCCESS',
            projectId,
            pinnedViews
        })),
    updateUIControl: ActionsBuilder.buildEntityUpdateUIControl(`api/project`,
        (uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
            type: 'UPDATE_UICONTROL_SUCCESS',
            uiControlInfo
        })),
    partialUpdateUIControl: ActionsBuilder.buildEntityPartialUpdateUIControl(`api/project`,
        (uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
            type: 'UPDATE_UICONTROL_SUCCESS',
            uiControlInfo
        })),
    updateUIControlOnClient: ActionsBuilder.buildEntityUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_UICONTROL_SUCCESS',
        uiControlInfo
    })),
    partialUpdateUIControlOnClient: ActionsBuilder.buildEntityPartialUpdateUIControlOnClient((uiControlInfo, dispatch) => dispatch(<UpdateUIControlAction>{
        type: 'UPDATE_UICONTROL_SUCCESS',
        uiControlInfo
    })),
    updateLayoutUIControl: ActionsBuilder.buildLayoutUpdateUIControl(EntityType.Project),
    partialUpdateLayoutUIControl: ActionsBuilder.buildLayoutPartialUpdateUIControl(EntityType.Project),
    requestProjects: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<ProjectInfo[]>(`api/project`)
            .then(data => dispatch({ type: 'RECEIVED_PROJECTS', projects: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_PROJECTS' });
    },
    getProjectsByIds: (projectIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ProjectInfo[]>(`api/project/get`, { ids: projectIds })
            .then(data => dispatch({ type: 'RECEIVED_PROJECTS_PART', projects: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_PROJECTS' });
    },
    createProject: (name: string, layoutId: string, isPrivate: boolean, configureConnections: boolean, openOnComplete: boolean):
        AppThunkAction<CreateProjectSuccessAction | RouterAction> => (dispatch, getState) => {
            post<ProjectInfo>(isPrivate ? `api/project/private` : `api/project`, { name, layoutId })
                .then(data => {
                    dispatch(<CreateProjectSuccessAction>{ type: 'CREATE_PROJECT_SUCCESS', project: data });
                    if (openOnComplete) {
                        dispatch(push({ pathname: `/project/${data.id}`, search: '' }, { configureConnections }));
                    }
                })
                .catch(defaultCatch(dispatch));
        },
    removeProjects: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        ids.length === 1
            ? remove<IDeletionResult>(`api/project/${ids[0]}`)
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_PROJECT_RESULT", deletionResult: [data] });
                    if (redirectBack) {
                        dispatch(push('/projects'));
                    }
                })
                .catch(defaultCatch(dispatch))
            : post<IDeletionResult[]>(`api/project/bulkDelete`, { ids })
                .then(data => {
                    dispatch({ type: "RECEIVED_REMOVE_PROJECT_RESULT", deletionResult: data });
                    if (redirectBack) {
                        dispatch(push('/projects'));
                    }
                })
                .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_PROJECT" });
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_PROJECT_RESULT" });
    },
    archiveProjects: (ids: string[], redirectBack?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<IArchivingResult[]>(`api/project/archive`, { ids })
            .then(data => {
                dispatch({ type: "RECEIVED_ARCHIVE_PROJECT_RESULT", archivingResult: data });
                if (redirectBack) {
                    dispatch(push('/projects'));
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: "LOAD_PROJECT" });
    },
    dismissArchivingResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_ARCHIVE_PROJECT_RESULT" });
    },
    bulkUpdate: (updates: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ProjectInfo[]>(`api/project/bulkUpdate`, updates)
            .then(data => dispatch({ type: 'BULK_UPDATE_PROJECT_SUCCESS', projects: data }))
            .catch(defaultCatch(dispatch));
    },
    updateImage: (projectId: string, logo: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const data = new FormData();
        data.set('image', logo);

        post<{ imageId: string }>(`api/project/${projectId}/image`, data)
            .then(_ => dispatch({ type: 'UPDATE_PROJECT_IMAGE', imageId: _.imageId, projectId: projectId }))
            .catch(defaultCatch(dispatch));
    },
    removeImage: (projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<void>(`api/project/${projectId}/image`)
            .then(_ => dispatch({ type: 'UPDATE_PROJECT_IMAGE', imageId: undefined, projectId: projectId }))
            .catch(defaultCatch(dispatch));
    },
    linkToO365Group: (projectId: string, linkData: ILinkDto<O365GroupLinkInfo>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ProjectInfo>(`api/project/${projectId}/link/o365Group`, linkData)
            .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToTeamsChannel: (projectId: string, linkData: ILinkDto<TeamsChannelLink>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ProjectInfo>(`api/project/${projectId}/link/teamsChannel`, linkData)
            .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToPlannerPlan: (projectId: string, linkData: ILinkDto<IPlanInfo> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/plannerPlan`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.O365Planner)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToPpmxTimeProject: (projectId: string, linkData: ILinkDto<PpmxTimeLinkInfo>): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/ppmxTimeProject`, linkData)
            .then(data => {
                if (data.error) {
                    dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                }
                dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
            })
            .catch(defaultCatch(dispatch));
    },
    deleteProjectToExternalSystemLink: (projectId: string, connectionId: string, sourceType: ExternalEpmConnectStore.SourceType):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            remove<ProjectInfo>(`api/project/${projectId}/link/${sourceType}/${connectionId}`)
                .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
                .catch(defaultCatch(dispatch));
        },
    refresh: (projectId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<SyncableProjectInfo>(`api/project/${projectId}/refresh`, {})
            .then(data => dispatch({ type: 'RECEIVED_PROJECT_SOURCE_INFOS', data }))
            .catch(defaultCatch(dispatch));
    },
    refreshMany: (projectIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<SyncableProjectInfo[]>(`api/project/refresh`, { ids: projectIds })
            .then(data => dispatch({ type: 'RECEIVED_PROJECTS_SOURCE_INFOS', data: data }))
            .catch(defaultCatch(dispatch));
        dispatch({ type: 'REQUEST_PROJECTS_SOURCE_INFOS' });
    },
    applyLayout: (projectId: string, layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<ProjectInfo>(`api/project/${projectId}/applyLayout/${layoutId}`, {})
            .then(data => {
                dispatch({ type: 'RECEIVED_PROJECT', project: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Project });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Project });
    },
    applyLayoutMany: (projectIds: string[], layoutId: string): AppThunkAction<KnownAction | ApplyLayout | LayoutApplied> => (dispatch, getState) => {
        post<ProjectInfo[]>(`api/project/applyLayout/${layoutId}`, { ids: projectIds })
            .then(data => {
                dispatch({ type: 'RECEIVED_PROJECTS_PART', projects: data });
                dispatch({ type: 'LAYOUT_APPLIED', entity: EntityType.Project });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'APPLY_LAYOUT', entity: EntityType.Project });
    },
    cloneProject: (projectId: string): AppThunkAction<CreateProjectSuccessAction | RouterAction> => (dispatch, getState) => {
        post<ProjectInfo>(`api/project/${projectId}/clone`, {})
            .then(data => {
                dispatch(<CreateProjectSuccessAction>{ type: 'CREATE_PROJECT_SUCCESS', project: data });
                dispatch(push(`/project/${data.id}`, {}));
            })
            .catch(defaultCatch(dispatch));
    },
    updateCalculation: (projectId: string, changes: Partial<ProjectCalculation>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{calculation: ProjectCalculation, updates: Dictionary<any>}>(`api/project/${projectId}/calculation`, changes)
            .then(_ => dispatch({ type: 'UPDATE_PROJECT_CALCULATION', projectId, ..._ }))
            .catch(defaultCatch(dispatch));
    },
    updatePriorityAlignment: (projectId: string, strategicPriorityId: string, impact: Impact):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<ProjectInfo>(`api/project/${projectId}/priorityAlignments`, { strategicPriority: { id: strategicPriorityId }, impact: impact })
                .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
                .catch(defaultCatch(dispatch));
        },
    recalculateAlignmentScore: (projectId: string):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<ProjectInfo>(`api/project/${projectId}/recalculateAlignmentScore`, {})
                .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
                .catch(defaultCatch(dispatch));
        },
    linkToJiraProject: (projectId: string, linkData: ILinkDto<IJiraLinkInfo> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/jira/project`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.Jira)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToFile: (projectId: string, fileUrl: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ProjectInfo>(`api/project/${projectId}/link/file`, { fileUrl: fileUrl })
            .then(data => dispatch({ type: 'RECEIVED_PROJECT', project: data }))
            .catch(defaultCatch(dispatch));
    },
    linkToVSTSProject: (projectId: string, linkData: ILinkDto<IVSTSLinkData> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/vsts/project`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.VSTS)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToPoProject: (projectId: string, linkData: ILinkDto<ISpoProject> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/spo/project`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.Spo)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToMondayComBoard: (projectId: string, linkData: ILinkDto<IMondayComBaseSourceData> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/mondaycom/board`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.MondayCom)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToSmartsheetSheet: (projectId: string, linkData: ILinkDto<ISmartsheetProjectSourceData> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/smartsheet/sheet`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.Smartsheet)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    linkToP4WProject: (projectId: string, linkData: ILinkDto<IP4WProject> & ISetAsPrimary):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<{ error: string, entity: ProjectInfo }>(`api/project/${projectId}/link/p4w/project`, linkData)
                .then(data => {
                    if (data.error) {
                        dispatch(NotificationsStore.actionCreators.pushNotification({ message: data.error, type: NotificationsStore.NotificationType.Error }));
                    } else if (linkData.setAsPrimary) {
                        dispatch(NotificationsStore.actionCreators.pushNotification(getPrimarySystemSwitchedMessage(SourceType.P4W)));
                    }
                    dispatch({ type: 'RECEIVED_PROJECT', project: data.entity });
                })
                .catch(defaultCatch(dispatch));
        },
    setFavorite: (projectId: string, isFavorite: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        (
            isFavorite
                ? post(`api/user/preferences/favorites/projects`, { id: projectId })
                : remove(`api/user/preferences/favorites/projects/${projectId}`)
        )
            .then(() => dispatch({ type: 'SET_PROJECT_IS_FAVORITE', projectId: projectId, isFavorite: isFavorite }))
            .catch(defaultCatch(dispatch))
    },
    requestAccess: (projectId: string, message?: string): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post(`api/project/${projectId}/requestAccess`, { message })
            .then(_ => dispatch(NotificationsStore.actionCreators.pushNotification({ message: `Thank you for your request! Access will be provided as soon as possible.` })))
            .catch(defaultCatch(dispatch));
    },
    saveKeyResult: (projectId: string, keyResultAssignment: KeyResultAssignment): AppThunkAction<KnownAction | NotificationsStore.KnownAction | ObjectivesLoadedAction> =>
        (dispatch, getState) => {
            post<{ keyResults: KeyResultAssignment[]; objectives: Objective[]; }>(`api/project/${projectId}/keyresult`, keyResultAssignment)
                .then(data => {
                    dispatch({ type: 'RECEIVED_KEY_RESULT_ASSIGNMENTS', projectId: projectId, keyResults: data.keyResults });
                    dispatch({ type: 'OBJECTIVES_LOADED', objectives: data.objectives, partial: true });
                })
                .catch(defaultCatch(dispatch));
        },
    removeKeyResults: (projectId: string, ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<KeyResultAssignment[]>(`api/project/${projectId}/keyresult`, { ids })
            .then(data => dispatch({ type: 'RECEIVED_KEY_RESULT_ASSIGNMENTS', projectId: projectId, keyResults: data }))
            .catch(defaultCatch(dispatch));
    },
    runTaskCompletedWorkRecalculation: (projectId: string): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post(`api/project/${projectId}/sync/completed-work`, { })
            .then(_ => dispatch(NotificationsStore.actionCreators.pushNotification({
                message: `Tasks Completed Work recalculation is in progress. Please allow some time for it to complete`,
                type: NotificationsStore.NotificationType.Success
            })))
            .catch(defaultCatch(dispatch));
    },
    runResourcePlanActualsRecalculation: (projectId: string): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post(`api/project/${projectId}/sync/resource-plan`, { })
            .then(_ => dispatch(NotificationsStore.actionCreators.pushNotification({
                message: `Resource Plan Actuals recalculation is in progress. Please allow some time for it to complete`,
                type: NotificationsStore.NotificationType.Success
            })))
            .catch(defaultCatch(dispatch));
    },
    setProjectBaseline: (projectId: string, setTasksBaseline: boolean, setKeyDatesBaseline: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<ProjectAttributesModel>(`api/project/${projectId}/baseline`, { setTasksBaseline, setKeyDatesBaseline })
            .then(data => dispatch({ type: 'RECEIVED_PROJECT_ATTRIBUTES', data }))
        .catch(defaultCatch(dispatch));
    },
    ...subentitiesActionCreators
};

const unloadedState: ProjectsListState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListLoading: false,
    isTasksLoading: false,
    isListUpdating: false,
    isUpdatingSections: false,

    tasks: {
        ...StoreHelper.create([]),
        isLoading: false,
        isListLoading: false
    },
    changeHistory: HISTORY_DEFAULT_STATE,
    groups: {
        ...StoreHelper.create([]),
        isLoading: false
    },
};

export const defaultReducer: Reducer<ProjectsListState> = (state: ProjectsListState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PROJECTS':
            {
                return {
                    ...state,
                    isListLoading: true
                };
            }
        case 'RECEIVED_PROJECTS':
            {
                return {
                    ...state,
                    ...StoreHelper.create(action.projects),
                    isListLoading: false
                };
            }
        case 'RECEIVED_PROJECTS_PART':
            {
                return {
                    ...state,
                    ...StoreHelper.union(state, action.projects),
                    isListLoading: false
                };
            }
        case 'CREATE_PROJECT_SUCCESS':
            {
                return {
                    ...state,
                    ...StoreHelper.addOrUpdate(state, action.project),
                    activeEntityId: action.isNotSetActiveEntity ? undefined : action.project.id,
                    activeEntity: action.isNotSetActiveEntity ? undefined : action.project,
                    isLoading: false
                };
            }
        case 'BULK_UPDATE_PROJECT_SUCCESS':
            {
                return {
                    ...state,
                    ...StoreHelper.union(state, action.projects),
                    isLoading: false
                };
            }
        case 'UPDATE_PROJECT_IMAGE':
            {
                return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) => partialUpdate(project, { imageId: action.imageId }));
            }
        case 'RECEIVED_PROJECT':
            if (state.activeEntity?.id === action.project?.id && action.project.tasks === undefined) {
                //keep project tasks as those are not embedded into project and therefore are not returned along with it
                action.project.tasks = state.activeEntity?.tasks;
                const tasksScheduleData = state.activeEntity.calculation.scheduleData?.[ppmxTaskConnectionId];
                if (tasksScheduleData) {
                    action.project.calculation.scheduleData[ppmxTaskConnectionId] = tasksScheduleData;
                }
            }

            if (state.activeEntity?.id === action.project?.id) {
                action.project.changeHistory = state.activeEntity.changeHistory;
            }

            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.project),
                activeEntity: state.activeEntityId === action.project.id ? action.project : state.activeEntity,
                isLoading: false
            };
        case 'RECEIVED_PROJECT_ATTRIBUTES':
            return {
                ...state,
                activeEntity: state.activeEntityId === action.data.id && state.activeEntity
                    ? {
                        ...state.activeEntity,
                        attributes: action.data.attributes,
                        warnings: action.data.warnings,
                        insights: action.data.insights,
                        keyDates: action.data.keyDates,
                        deliverables: action.data.deliverables,
                    }
                    : state.activeEntity
            };
        case 'REMOVED_PROJECTS_SOURCE_INFOS':
            return {
                ...state,
                ...StoreHelper.applyHandler(state, state.allIds,
                    (project: ProjectInfo) => partialUpdate(project, { sourceInfos: project.sourceInfos.filter(_ => _.connectionId !== action.connectionId) })),
                activeEntity: state.activeEntity
                    ? { ...state.activeEntity, sourceInfos: state.activeEntity.sourceInfos.filter(_ => _.connectionId !== action.connectionId) }
                    : state.activeEntity
            };
        case 'RECEIVED_PROJECT_SOURCE_INFOS':
            return {
                ...state,
                ...StoreHelper.applyHandler(state, action.data.id,
                    (project: ProjectInfo) => partialUpdate(project, { sourceInfos: action.data.sourceInfos, isSyncable: action.data.isSyncable })),
                activeEntity: state.activeEntityId === action.data.id && state.activeEntity
                    ? { ...state.activeEntity, sourceInfos: action.data.sourceInfos, isSyncable: action.data.isSyncable }
                    : state.activeEntity
            };
        case 'RECEIVED_PROJECTS_SOURCE_INFOS':
            const map: Dictionary<SyncableProjectInfo> = {};
            for (const item of action.data) {
                map[item.id] = item;
            }
            return {
                ...StoreHelper.applyHandler(state, action.data.map(_ => _.id),
                    (project: ProjectInfo) => partialUpdate(project, { sourceInfos: map[project.id].sourceInfos, isSyncable: map[project.id].isSyncable })),
                isListUpdating: false,
            }
        case "REQUEST_PROJECTS_SOURCE_INFOS":
            return {
                ...state,
                isListUpdating: true,
            }
        case 'RECEIVED_REMOVE_PROJECT_RESULT':
            let newState = state;
            if (action.deletionResult && action.deletionResult.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'RECEIVED_ARCHIVE_PROJECT_RESULT':
            let afterArchivingState = state;
            if (action.archivingResult && action.archivingResult.length) {
                action.archivingResult.forEach(result => {
                    if (result.isArchived) {
                        afterArchivingState = { ...afterArchivingState, ...StoreHelper.remove(afterArchivingState, result.id) };
                    }
                });
            }
            return {
                ...afterArchivingState,
                isLoading: false,
                archivingResult: action.archivingResult
            };
        case 'LOAD_PROJECT':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id === action.id ? state.activeEntity : undefined,
                isLoading: true
            };
        case 'UPDATING_PROJECT_SECTIONS':
            return {
                ...state,
                isUpdatingSections: true
            };
        case 'UPDATE_PROJECT_SECTION_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.projectId,
                        (project: ProjectInfo) => Object.assign({}, project, { sections: action.sections })),
                    isUpdatingSections: false,
                };
            }
        case 'UPDATE_PROJECT_PINNED_VIEWS_SUCCESS':
            {
                return {
                    ...StoreHelper.applyHandler(state,
                        action.projectId,
                        (project: ProjectInfo) => Object.assign({}, project, { pinnedViews: action.pinnedViews })),
                };
            }
        case 'UPDATE_UICONTROL_SUCCESS':
            {
                return StoreHelper.applyHandler(state, action.uiControlInfo.entityId,
                    (project: ProjectInfo) => MetadataService.UpdateUIControlSettings(project, action.uiControlInfo));
            }
        case 'UPDATE_PROJECT_KEYDATES':
            {
                return StoreHelper.applyHandler(state, action.entityId,
                    (project: ProjectInfo) => partialUpdate(project, {
                        keyDates: addOrUpdateOrRemove(project.keyDates, action.addOrUpdate, action.remove),
                        warnings: action.warnings
                    }));
            }
        case 'UPDATE_PROJECT_RISKS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    risks: addOrUpdateOrRemove(project.risks, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_ITERATIONS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    iterations: addOrUpdateOrRemove(project.iterations, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_ISSUES':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    issues: addOrUpdateOrRemove(project.issues, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_LESSONSLEARNED':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    lessonsLearned: addOrUpdateOrRemove(project.lessonsLearned, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_ACTIONITEMS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    actionItems: addOrUpdateOrRemove(project.actionItems, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_KEYDECISIONS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    keyDecisions: addOrUpdateOrRemove(project.keyDecisions, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_STEERINGCOMMITTEE':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    steeringCommittee: addOrUpdateOrRemove(project.steeringCommittee, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_DELIVERABLES':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    deliverables: addOrUpdateOrRemove(project.deliverables, action.addOrUpdate, action.remove),
                    warnings: action.warnings
                }));
            }
        case 'UPDATE_PROJECT_PURCHASEORDERS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    purchaseOrders: addOrUpdateOrRemove(project.purchaseOrders, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_INVOICES':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    invoices: addOrUpdateOrRemove(project.invoices, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_CHANGEREQUESTS':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    changeRequests: addOrUpdateOrRemove(project.changeRequests, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_DEPENDENCIES':
            {
                return StoreHelper.applyHandler(state, action.entityId, (project: ProjectInfo) => partialUpdate(project, {
                    dependencies: addOrUpdateOrRemove(project.dependencies, action.addOrUpdate, action.remove)
                }));
            }
        case 'UPDATE_PROJECT_CALCULATION':
            {
                return StoreHelper.applyHandler(state, action.projectId, 
                    (project: ProjectInfo) => partialUpdate(project, { calculation: action.calculation, attributes: { ...project.attributes, ...action.updates } }));
            }
        case 'SET_PROJECT_IS_FAVORITE':
            {
                return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) => partialUpdate(project, { isFavorite: action.isFavorite }));
            }
        case 'UPDATE_PROJECT_SETTINGS':
            return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) =>
                partialUpdate(project, {
                    insights: action.info.insights,
                    keyDates: action.info.keyDates,
                    warnings: action.info.warnings,
                    settings: action.info.settings,
                    attributes: action.info.attributes,
                }));
        case 'UPDATE_PROJECT_AIINSIGHTS':
            {
                return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) => partialUpdate(project, { aiInsights: action.info.aiInsights ?? {} }));
            }
        case 'UPDATED_PROJECT_AIINSIGHTS':
            {
                return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) => { project.aiInsights.isAiInsightsLoading = action.isAiInsightsLoading; return project; });
            }
        case 'UPDATED_PROJECT_TASKS_CALCULATION':
            return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) => ({
                ...project,
                calculation: {
                    ...project.calculation,
                    ...(action.scheduleData
                        ? {
                            scheduleData: {
                                ...project.calculation.scheduleData,
                                [action.scheduleData.connectionId]: action.scheduleData.data,
                            }
                        }
                        : undefined),
                    ...(action.teamMembersData
                        ? {
                            teamMembersData: {
                                ...project.calculation.teamMembersData,
                                [action.teamMembersData.connectionId]: action.teamMembersData.data,
                            }
                        }
                        : undefined),
                    ...(action.taskProgressesData
                        ? {
                            taskProgresses: {
                                ...project.calculation.taskProgresses,
                                [action.taskProgressesData.connectionId]: action.taskProgressesData.data,
                            }
                        }
                        : undefined),
                }
            }));
        case 'RECEIVED_KEY_RESULT_ASSIGNMENTS':
            {
                return StoreHelper.applyHandler(state, action.projectId, (project: ProjectInfo) => partialUpdate(project, { keyResults: action.keyResults }));
            }
        default:
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

export const reducer: Reducer<ProjectsListState> = (state: ProjectsListState = unloadedState, incomingAction: Action) => {
    return defaultReducer(
        historyStore.reducer(
            tasksStore.reducer(
                resourcePlanStore.reducer(
                    importExportReducer(
                        {
                            ...state,
                            groups: groupsStore.reducer(state.groups, incomingAction),
                        },
                        incomingAction),
                    incomingAction),
                incomingAction),
            incomingAction),
        incomingAction
    );
}

export const actionCreators = {
    ...defaultActionCreators,
    ...importExportActionCreators,
    ...resourcePlanStore.actionCreators,
    ...tasksStore.actionCreators,
    ...historyStore.actionCreators
};

export const groupsCreators = groupsStore.actionCreators;
import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import { SourceType } from "../../store/ExternalEpmConnectStore";
import * as Notifications from "../../store/NotificationsStore";
import { IContainerSectionInfo, LayoutableSectionsContainer } from '../common/sectionsControl/SectionsContainer';
import * as Metadata from '../../entities/Metadata';
import { Dictionary, EntityType, Impact, StatusCategory, UpdateContext, IInsightsData, IWarning } from "../../entities/common";
import {
    IKeyDateAttrs, IRiskAttrs, buildActions, buildKeyDateActions, buildRiskActions, 
    buildActionItemActions, buildKeyDecisionActions
} from "../../entities/Subentities";
import * as ProgramsControl from "../common/sectionsControl/uiControls/ProgramsControl";
import * as StrategicAlignmentControl from '../common/sectionsControl/uiControls/StrategicAlignmentControl';
import * as StatusesControl from '../common/sectionsControl/uiControls/StatusesControl';
import * as PortfolioSummaryControl from '../common/sectionsControl/uiControls/PortfolioSummaryControl';
import * as KeyDatesControl from '../common/sectionsControl/uiControls/KeyDatesControl';
import * as RisksControl from '../common/sectionsControl/uiControls/RisksControl';
import * as ActionItemsControl from '../common/sectionsControl/uiControls/ActionItemsControl';
import * as KeyDecisionsControl from '../common/sectionsControl/uiControls/KeyDecisionsControl';
import * as ResourcePlanControl from '../common/sectionsControl/uiControls/ResourcePlanControl';
import * as EmbeddedContentControl from '../common/sectionsControl/uiControls/EmbeddedContentControl';
import * as TimelineControl from '../common/sectionsControl/uiControls/TimelineControl';
import { DetailsSpinner } from "../common/Spinner";
import { Reporting, ReportNav } from "../utils/reporting";
import * as LayoutsStore from '../../store/layouts';
import * as WarningsTypeMapsStore from "../../store/warningsTypeMapsStore";
import { ILinkDto } from '../../store/integration/common';
import { EntitySectionWarning } from "../common/warnings/EntitySectionWarning";
import { warningService } from '../../store/services/warningService';
import { TeamsChannelLink, TeamsChannelActions } from '../integration/TeamsChannelConnectControl';
import { StatusCalculationTypes, Insights, TenantState } from '../../store/Tenant';
import { nameof } from '../../store/services/metadataService';
import { ViewTypeViews, buildViewTypeHeaderRender, buildViewTypeSelect, withViewType } from '../common/ViewTypeSelect';
import * as CalendarStore from '../../store/CalendarStore';
import { O365ConnectActions } from '../integration/Office365/Group/O365GroupConnectControl';
import AccessRequestConfirmationPanel from '../common/AccessRequestConfirmationPanel';
import { UserState, isInReadonlyMode } from '../../store/User';
import SubEntitySelectionPanel from './SubEntitySelectionPanel';
import { ImportedFromFakeField } from '../views/list/columns/subentity/ImportedFrom';
import { ViewService } from '../../services/ViewService';
import { get } from '../../fetch-interceptor';
import { IControlConfiguration } from '../common/interfaces/ISectionUIControlProps';
import { Views } from '../../store/services/viewSaver';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import { IFinancialsConfiguration } from '../common/FinancialsContent';
import { buildIWithBenefitsCustomRenders, buildIWithBenefitsValidators, rendersBuilder, validators } from '../field/Fields';
import { buildTimelineItem } from './timeline';
import { renderMarkerTooltipContent, renderSegmentContent } from './commonTimeline';
import PortfolioHeader from './PortfolioHeader';
import { arraysEqual } from '../utils/common';
import { buildReadOnlyStatusFieldsNames, IFieldsAreaConfiguration } from '../common/sectionsControl/uiControls/fieldsArea/common';

type ActionProps = {
    projectsActions: typeof ProjectsListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    layoutsActions: ReturnType<typeof LayoutsStore.actionCreators.forEntity>;
    warningsTypeMapsActions: typeof WarningsTypeMapsStore.actionCreators;
    calendarActions: typeof CalendarStore.actionCreators;
};
type StateProps = {
    tenant: TenantState;
    user: UserState;
    entity?: PortfoliosListStore.Portfolio;
    warnings: IWarning[];
    isLoading: boolean;
    isUpdatingSections: boolean;
    layouts: LayoutsStore.LayoutsState;
    portfolioFields: Metadata.Field[];
    projectFields: Metadata.Field[];
    programFields: Metadata.Field[];
    keyDateFields: Metadata.Field[];
    riskFields: Metadata.Field[];
    portfolioReports: ReportNav[];
    warningsTypeMap: WarningsTypeMapsStore.EntityWarningsTypeMap;
    sectionWarningsTypeMap: Dictionary<string[]>;
    insights: Insights;
    isReadonlyMode: boolean;
    projects: ProjectsListStore.ProjectInfo[];
    programs: ProgramsListStore.Program[];
};
type PortfolioDetailsProps =
    StateProps
    & ActionProps
    & RouteComponentProps<{ id: string }>;

type PortfolioDetailsState = {
    shareWith?: string;
}

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    ProgramsControl: ProgramsControl.IConfiguration;
    KeyDatesControl: KeyDatesControl.IConfiguration;
    ActionItemsControl: ActionItemsControl.IConfiguration;
    KeyDecisionsControl: KeyDecisionsControl.IConfiguration;
    PortfolioSummaryControl: PortfolioSummaryControl.IConfiguration;
    BudgetControl: IFinancialsConfiguration;
    BenefitsControl: IFinancialsConfiguration;
    StatusesControl: StatusesControl.IConfiguration;
    StrategicAlignmentControl: StrategicAlignmentControl.IConfiguration;
    RisksControl: RisksControl.IConfiguration;
    ResourcePlanControl: ResourcePlanControl.IConfiguration;
    EmbeddedContentControl: EmbeddedContentControl.IConfiguration;
    TimelineControl: TimelineControl.IConfiguration;
}
const entityName = EntityType.Portfolio;
class PortfolioDetails extends React.Component<PortfolioDetailsProps, PortfolioDetailsState> {
    constructor(props: PortfolioDetailsProps) {
        super(props);
        this.state = {
            shareWith: new URLSearchParams(this.props.location.search).get('shareWith') ?? undefined
        };
    }
    componentWillMount() {
        this.props.warningsTypeMapsActions.loadWarningsTypeMap(entityName);
        this.props.portfoliosActions.loadPortfolio(this.props.match.params.id);
        this.props.calendarActions.load();
    }

    componentWillReceiveProps(nextProps: PortfolioDetailsProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            this.props.portfoliosActions.loadPortfolio(nextProps.match.params.id);
        }
        if (nextProps.entity && !arraysEqual(this.props.entity?.projectIds, nextProps.entity?.projectIds)) {
            const toLoadProjects = nextProps.entity.projectIds.filter(_ => !nextProps.projects.find(p => p.id === _));
            if (toLoadProjects.length > 0) {
                this.props.projectsActions.getProjectsByIds(toLoadProjects);
            }
        }
        if (nextProps.entity && !arraysEqual(this.props.entity?.programIds, nextProps.entity?.programIds)) {
            const toLoadPrograms = nextProps.entity.programIds.filter(_ => !nextProps.programs.find(p => p.id === _));
            if (toLoadPrograms.length > 0) {
                this.props.programsActions.getProgramsByIds(toLoadPrograms);
            }
        }
    }

    public render() {
        const { entity, warnings, isLoading, layouts, isUpdatingSections, portfolioReports, warningsTypeMap } = this.props;
        const { shareWith } = this.state;

        const o365GroupActions: O365ConnectActions = {
            linkToO365Group: this.linkToO365Group,
            deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365Group),
            showLinkEngagementsOption: false
        };
        const teamsActions: TeamsChannelActions = {
            linkToTeamsChannel: this.linkToTeamsChannel,
            deleteLink: (connectionId: string) => this.deleteLink(connectionId, SourceType.O365TeamsChannel)
        };

        return <DetailsSpinner isLoading={isLoading}>
            {entity && <PortfolioHeader
                entity={entity}
                warnings={warnings}
                warningsTypeMap={warningsTypeMap?.common}
                actions={{
                    updateImage: this.updateImage,
                    removeImage: this.removeImage,
                    layoutActions: {
                        viewLayout: this.viewLayout,
                        applyLayout: this.applyLayout,
                        updateEntityLayout: this.updateDefaultLayout,
                        updateEntityPinnedViews: this.updatePinnedViews,
                        saveLayout: this.saveLayout,
                        deleteLayout: this.deleteLayout
                    },
                    removePortfolio: this.removePortfolio,
                    updateInsights: this.updateInsights,
                    "ExternalEpmConnectControl": {
                        [SourceType.O365Group]: o365GroupActions,
                        [SourceType.O365TeamsChannel]: teamsActions
                    },
                }}
            />}
            {entity && entity.sections &&
                <LayoutableSectionsContainer
                    key="sections"
                    entity={entity}
                    entityType={EntityType.Portfolio}
                    warnings={warnings}
                    onHeaderMiddleRender={this._onSectionHeaderMiddlePartRender}
                    reporting={{ reports: portfolioReports, onClick: this._onReportClick }}
                    layouts={layouts}
                    isUpdatingSections={isUpdatingSections}
                    controlsConfig={this._buildControlsConfigurations()}
                />}
            {
                entity && shareWith &&
                <AccessRequestConfirmationPanel
                    entity={entity}
                    entityType={EntityType.Portfolio}
                    entityTypeLabel="Portfolio"
                    userId={shareWith}
                    layouts={layouts}
                    onDismiss={() => {
                        this.setState({ shareWith: undefined });
                        const query = new URLSearchParams(this.props.location.search);
                        if (query.has('shareWith')) {
                            query.delete('shareWith');
                            this.props.history.replace({ search: query.toString() });
                        }
                    }}
                />
            }
        </DetailsSpinner>;
    }

    private _buildControlsConfigurations = (): IConfiguration => {
        const { warningsTypeMap, insights, entity, portfoliosActions, layouts, user, isReadonlyMode, projects, programs, portfolioFields,
            programFields, projectFields, keyDateFields } = this.props;
        const targetedwarningsTypeMap = warningsTypeMap.targeted;
        const analyticsProps = { user: user, parentType: EntityType.Portfolio };
        const { entityId, entityActions, actions } = buildActions(entity!.id, isReadonlyMode, analyticsProps, layouts, portfoliosActions, portfoliosActions.loadPortfolio);

        return {
            ['TimelineControl']: {
                datacontext: {
                    timeline: {
                        item: buildTimelineItem(entity!, programs, projects, portfolioFields, programFields, projectFields, keyDateFields),
                        displayToday: true,
                        startDate: entity!.attributes.StartDate,
                        finishDate: entity!.attributes.FinishDate,
                        renderSegmentContent: renderSegmentContent,
                        renderMarkerTooltipContent: renderMarkerTooltipContent
                    }
                },
            },
            ["FieldsArea"]: {
                customFieldValidator: {...validators, ...buildIWithBenefitsValidators()},
                elementCustomRender: {...rendersBuilder(), ...buildIWithBenefitsCustomRenders()},
                datacontext: {
                    entityId,
                    entityType: EntityType.Portfolio,
                    readOnlyFields: buildReadOnlyStatusFieldsNames(insights.portfolio.statusCalculation,
                        PortfoliosListStore.statuses, !entity?.insights.statusCalculationDisabled),
                    warningsTypeMap: targetedwarningsTypeMap["Details"] && targetedwarningsTypeMap["Details"]["FieldsArea"]
                },
                actions: {
                    ...entityActions,
                    onEditComplete: this.onEditComplete,
                }
            },
            ["StatusesControl"]: {
                ...buildViewTypeSelect(ViewTypeViews.StatusesControl),
                datacontext: {
                    entityId,
                    entityType: EntityType.Portfolio,
                    statusCalculation: insights.portfolio.statusCalculation,
                    statuses: PortfoliosListStore.statuses,
                    warningsTypeMap: targetedwarningsTypeMap["Statuses"] && targetedwarningsTypeMap["Statuses"]?.["StatusesControl"]
                },
                actions: {
                    ...entityActions,
                    onEditComplete: this.onEditCompleteMultipleAttrs,
                    resetStatus: this.resetStatus,
                    loadStatusHistory: this.loadHistory,
                    deleteStatusHistory: this.deleteHistory,
                }
            },
            ["PortfolioSummaryControl"]: {},
            ["KeyDatesControl"]: {
                settingsBuilder: KeyDatesControl.settingsBuilder,
                headerRender: buildViewTypeHeaderRender(ViewTypeViews.KeyDatesControl),
                datacontext: {
                    theOnlyEditableFieldsForLinkedEntities: [nameof<IKeyDateAttrs>("ShowOnTimeline")],
                    fakeFields: [ImportedFromFakeField],
                    readOnlyFields: this.isKeyDatesStatusCalculationDisabled()
                        ? [nameof<IKeyDateAttrs>("Status")]
                        : []
                },
                actions: buildKeyDateActions(entityId, analyticsProps, actions, portfoliosActions, keyDateFields, this._renderKeyDateImport())
            },
            ["RisksControl"]: {
                settingsBuilder: withViewType(Views.List),
                datacontext: {
                    fakeFields: [ImportedFromFakeField],
                    theOnlyEditableFieldsForLinkedEntities: []
                },
                actions: buildRiskActions(entityId, analyticsProps, actions, portfoliosActions, this._renderRiskImport())
            },
            ['ProgramsControl']: {
                ...buildViewTypeSelect(ViewTypeViews.ProgramsControl),
                actions: {
                    addProgram: this.addProgram,
                    removePrograms: this.removePrograms,
                    addProject: this.addProjects,
                    removeProjects: this.removeProjects,
                    updateUIControl: entityActions.updateUIControl
                }
            },
            ["BudgetControl"]: {
                actions: { updateCalculation: this.updateCalculation }
            },
            ["BenefitsControl"]: {
                actions: { updateCalculation: this.updateCalculation }
            },
            ["StrategicAlignmentControl"]: {
                actions: {
                    updatePriorityAlignment: this.updatePriorityAlignment,
                    recalculateAlignmentScore: this.recalculateAlignmentScore,
                }
            },
            ["ActionItemsControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildActionItemActions(entityId, analyticsProps, actions, portfoliosActions)
            },
            ["KeyDecisionsControl"]: {
                settingsBuilder: withViewType(Views.List),
                actions: buildKeyDecisionActions(entityId, analyticsProps, actions, portfoliosActions)
            },
            ['ResourcePlanControl']: {
                ...buildViewTypeSelect(ViewTypeViews.ResourcePlanControl),
                datacontext: { entityType: EntityType.Portfolio },
                actions: {
                    addResources: (resourceIds) =>
                        this.props.portfoliosActions.addResources(this.props.entity!.id, resourceIds),
                    createResource: (name, layoutId, auto, startDate, finishDate) =>
                        this.props.portfoliosActions.createResourceAndAdd(entityId, name, layoutId, auto, startDate, finishDate),
                    replaceResources: (prev, next, keepRate, sumUpPlanHours, sumUpActualHours) =>
                        this.props.portfoliosActions.replaceResource(entityId, prev, next, keepRate, sumUpPlanHours, sumUpActualHours),
                    removeResources: ids => this.props.portfoliosActions.removeResources(entityId, ids),
                    updateUIControl: entityActions.updateUIControl
                }
            },
            ['EmbeddedContentControl']: {
                datacontext: { entityType: EntityType.Portfolio },
                helpUrl: "https://help.ppm.express/89502-ppm-express-how-to-articles/2416583",
                actions: {
                    updateUiControl: entityActions.updateUIControl
                }
            }
        }
    }

    private _onReportClick = (report: ReportNav) => {
        Reporting.openPortfolioReport(this.props.history, report, this.props.entity!);
    }

    private onEditComplete = (fieldName: string, fieldValue: any): void => {
        if (!fieldName) {
            return;
        }

        this.props.portfoliosActions.updateAttributes(this.props.entity!.id, { [fieldName]: fieldValue });
    }

    private onEditCompleteMultipleAttrs = (attrs: Dictionary<string | number | undefined>, context: Dictionary<UpdateContext>): void => {
        this.props.portfoliosActions.updateAttributes(this.props.entity!.id, attrs, context);
    }

    private deleteHistory = (attributeName: string, id: string | undefined): void => {
        this.props.portfoliosActions.deleteHistory(this.props.entity!.id, attributeName, id);
    }

    private loadHistory = (attributeName?: string) => {
        this.props.portfoliosActions.loadHistory(this.props.entity!.id, attributeName);
    }

    private resetStatus = (statusAttributeName: string): void => {
        this.props.portfoliosActions.resetPortfolioStatus(this.props.entity!.id, statusAttributeName);
    }

    private updateInsights = (data: Partial<IInsightsData>): void => {
        this.props.portfoliosActions.updateInsights(this.props.entity!.id, data);
        this.props.notificationsActions.pushNotification({ message: `Autocalculation settings have been changed.` });
    }

    private addProjects = (projectIds: string[]) => {
        this.props.portfoliosActions.addProjects(this.props.entity!.id, projectIds);
    }

    private removeProjects = (projectIds: string[]) => {
        this.props.portfoliosActions.removeProjects(this.props.entity!.id, projectIds);
    }

    private addProgram = (programIds: string[]) => {
        this.props.portfoliosActions.addPrograms(this.props.entity!.id, programIds);
    }

    private removePrograms = (programIds: string[]) => {
        this.props.portfoliosActions.removePrograms(this.props.entity!.id, programIds);
    }

    private importKeyDates = (subentityToParentMap: Dictionary<string[]>) => {
        this.props.portfoliosActions.linkKeyDates(this.props.entity!.id, subentityToParentMap);
    }

    private importRisks = (subentityToParentMap: Dictionary<string[]>) => {
        this.props.portfoliosActions.linkRisks(this.props.entity!.id, subentityToParentMap);
    }

    private updateImage = (logo: File) => {
        this.props.portfoliosActions.updateImage(this.props.entity!.id, logo);
    }

    private removeImage = () => {
        this.props.portfoliosActions.removeImage(this.props.entity!.id);
    }

    private updatePriorityAlignment = (strategicPriorityId: string, impact: Impact) => {
        this.props.portfoliosActions.updatePriorityAlignment(this.props.entity!.id, strategicPriorityId, impact);
    }

    private recalculateAlignmentScore = () => {
        this.props.portfoliosActions.recalculateAlignmentScore(this.props.entity!.id);
    }

    private updateCalculation = (changes: Partial<PortfoliosListStore.PortfolioCalculation>) => {
        this.props.portfoliosActions.updateCalculation(this.props.entity!.id, changes);
    }

    private isKeyDatesStatusCalculationDisabled() {
        const { insights, entity } = this.props;
        return insights.keyDate.statusCalculation === StatusCalculationTypes.Auto && !entity?.insights.keyDateStatusCalculationDisabled;
    }

    private _getRollupSources = () => {
        return get<{ programs: ProgramsListStore.Program[], projects: ProjectsListStore.ProjectInfo[] }>(`api/portfolio/${this.props.match.params.id}/rollupSources`)
            .then(data => [...data.projects, ...data.programs]);
    }

    private _renderKeyDateImport = (): undefined | ((props: { onDismiss: () => void }) => JSX.Element) => {
        if (this.props.entity!.projectIds.length === 0 && this.props.entity!.programIds.length === 0) {
            return undefined;
        }
        const projectNameField = this.props.projectFields.find(_ => _.name === nameof<ProjectsListStore.ProjectAttrs>("Name"))!;
        const programNameField = this.props.programFields.find(_ => _.name === nameof<ProgramsListStore.ProgramAttrs>("Name"))!;
        const projectNameColumn = ViewService.buildColumn(projectNameField.name, { Name: projectNameField }, undefined, false, EntityType.Project, undefined, true);
        const programNameColumn = ViewService.buildColumn(programNameField.name, { Name: programNameField }, undefined, false, EntityType.Program, undefined, true);
        return (props: { onDismiss: () => void }) => <SubEntitySelectionPanel
            multichoice
            load={this._getRollupSources}
            getSubentities={(entity: ProjectsListStore.ProjectInfo | ProgramsListStore.Program) => entity.keyDates}
            subentityType={EntityType.KeyDate}
            subEntityNameField={this.props.keyDateFields.find(_ => _.name === nameof<IKeyDateAttrs>("Name"))!}
            filters={{
                disabledIds: this.props.entity!.keyDates.filter(_ => !!_.externalId).map(_ => _.externalId!)
            }}
            getName={(entity: ProjectsListStore.ProjectInfo | ProgramsListStore.Program) => entity.attributes[projectNameField.name] ?? entity.attributes[programNameField.name]}
            entityNameLabel="Project / Program Name"
            subentityNameLabel="Key Date Name"
            onRenderNameColumn={(entity: ProjectsListStore.ProjectInfo | ProgramsListStore.Program) => !!(entity as ProgramsListStore.Program)?.projectIds
                ? programNameColumn?.onRender!(entity, undefined, programNameColumn)
                : projectNameColumn?.onRender!(entity, undefined, projectNameColumn)}
            header={{
                title: 'Import Key Dates',
                description: 'Select key dates that you want to import from related Programs and Projects to be available for viewing in current Portfolio'
            }}
            onDismiss={props.onDismiss}
            onSelectionComplete={(selected) => this.importKeyDates(selected.reduce(
                (cum, cur) => ({ ...cum, [cur.entityId]: [...(cum[cur.entityId] ?? []), cur.subentityId] }), {}))}
        />
    }

    private _renderRiskImport = (): undefined | ((props: { onDismiss: () => void }) => JSX.Element) => {
        if (this.props.entity!.projectIds.length === 0 && this.props.entity!.programIds.length === 0) {
            return undefined;
        }

        const projectNameField = this.props.projectFields.find(_ => _.name === nameof<ProjectsListStore.ProjectAttrs>("Name"))!;
        const programNameField = this.props.programFields.find(_ => _.name === nameof<ProgramsListStore.ProgramAttrs>("Name"))!;
        const projectNameColumn = ViewService.buildColumn(projectNameField.name, { Name: projectNameField }, undefined, false, EntityType.Project, undefined, true);
        const programNameColumn = ViewService.buildColumn(programNameField.name, { Name: programNameField }, undefined, false, EntityType.Program, undefined, true);
        return (props: { onDismiss: () => void }) => <SubEntitySelectionPanel
            multichoice
            load={this._getRollupSources}
            subentityType={EntityType.Risk}
            subEntityNameField={this.props.riskFields.find(_ => _.name === nameof<IRiskAttrs>("Name"))!}
            filters={{
                disabledIds: this.props.entity!.risks.filter(_ => !!_.externalId).map(_ => _.externalId!)
            }}
            getName={(entity: ProjectsListStore.ProjectInfo | ProgramsListStore.Program) => entity.attributes[projectNameField.name] ?? entity.attributes[programNameField.name]}
            getSubentities={(entity: ProjectsListStore.ProjectInfo | ProgramsListStore.Program) => entity.risks}
            entityNameLabel="Project / Program Name"
            subentityNameLabel="Risk Name"
            onRenderNameColumn={(entity: ProjectsListStore.ProjectInfo | ProgramsListStore.Program) => !!(entity as ProgramsListStore.Program)?.projectIds
                ? programNameColumn?.onRender!(entity, undefined, programNameColumn)
                : projectNameColumn?.onRender!(entity, undefined, projectNameColumn)}
            header={{
                title: 'Import Risks',
                description: 'Select risks that you want to import from related Programs and Projects to be available for viewing in current Portfolio'
            }}
            onDismiss={props.onDismiss}
            onSelectionComplete={(selected) => this.importRisks(selected.reduce((cum, cur) => ({ ...cum, [cur.entityId]: [...(cum[cur.entityId] ?? []), cur.subentityId] }), {}))}
        />
    }

    private _onSectionHeaderMiddlePartRender = (section: IContainerSectionInfo): JSX.Element | null => {
        return (
            <EntitySectionWarning warningsTypes={this.props.sectionWarningsTypeMap[section.name]}
                warnings={this.props.warnings} />
        );
    }

    private applyLayout = (layout: Metadata.Layout) => {
        this.props.portfoliosActions.applyLayout(this.props.entity!.id, layout.id);
        this.props.notificationsActions.pushNotification({ message: `Layout '${layout.name}' applied`, type: Notifications.NotificationType.Info });
    }

    private viewLayout = (layout?: Metadata.Layout) => {
        this.props.layoutsActions.viewLayout(layout?.id);
    }

    private updateDefaultLayout = (updates: Dictionary<Metadata.IUpdateSectionInfo>) => {
        const entity = this.props.entity!;
        if (this.props.isReadonlyMode) {
            this.props.portfoliosActions.updateSectionsOnClient(entity, updates);
        } else {
            this.props.portfoliosActions.updateSections(entity.id, updates);
        }
    }

    private updatePinnedViews = (pinnedViews: string[]) => {
        this.props.portfoliosActions.updatePinnedViews(this.props.entity!.id, pinnedViews);
    }

    private deleteLayout = (layout: Metadata.Layout) => {
        this.props.layoutsActions.removeLayout(layout.id);
        this.props.notificationsActions.pushNotification({ message: `${layout.isView ? 'View' : 'Layout'} '${layout.name}' deleted`, type: Notifications.NotificationType.Info });
    }

    private saveLayout = (layoutId: string | undefined, sections: Metadata.Section[], update: Metadata.IUpdateLayoutInfo, callback?: (layoutId: string) => void) => {
        this.props.layoutsActions.saveLayout(layoutId, sections, update, callback);
        this.props.notificationsActions.pushNotification({
            message: `${update.isView ? 'View' : 'Layout'} '${update.name}' ${layoutId ? 'updated' : 'created'}`,
            type: Notifications.NotificationType.Info
        });
    }

    private removePortfolio = () => {
        this.props.portfoliosActions.removePortfolios([this.props.entity!.id], true);
    }

    private linkToO365Group = (linkData: ILinkDto<ProjectsListStore.O365GroupLinkInfo>) => {
        this.props.portfoliosActions.linkToO365Group(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking portfolio '${this.props.entity!.attributes.Name}' to o365 group has been started.` });
        analytics.trackLink('Linked Portfolio to o365 group', this.props.user);
    }

    private linkToTeamsChannel = (linkData: ILinkDto<TeamsChannelLink>) => {
        this.props.portfoliosActions.linkToTeamsChannel(this.props.entity!.id, linkData);
        this.props.notificationsActions.pushNotification({ message: `Linking portfolio '${this.props.entity!.attributes.Name}' to teams channel has been started.` });
        analytics.trackLink('Linked Portfolio to teams channel', this.props.user);
    }

    private deleteLink = (connectionId: string, type: SourceType) => {
        this.props.portfoliosActions.deletePortfolioToExternalSystemLink(this.props.entity!.id, connectionId, type);
        this.props.notificationsActions.pushNotification({ message: `Unlinking portfolio '${this.props.entity!.attributes.Name}' has been started.` });
    }
}

function ensureProjectWarning(warnings: IWarning[], portfolio: PortfoliosListStore.Portfolio, state: ApplicationState): IWarning[] {
    if (state.tenant.insights.portfolioWarningsCalculationDisabled || portfolio.insights.warningsCalculationDisabled) {
        return warnings;
    }

    const warning: IWarning = {
        type: "ProjectWithWarning_OverallStatusOnTrack",
        text: "At least one Project has warning. Please consider changing Portfolio Overall Status."
    }

    if (warnings.find(_ => _.type === warning.type)) {
        return warnings;
    }

    const portfolioFields = state.fields[EntityType.Portfolio].allIds.map(_ => state.fields[EntityType.Portfolio].byId[_]);
    const portfolioOverallStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Portfolio, portfolioFields)!;
    if (portfolioOverallStatusDescriptor.isInCategory(portfolio.attributes.OverallStatus, StatusCategory.Green)) {
        for (const projectId of portfolio.projectIds) {
            const projectInfo = state.projectsList.byId[projectId];
            if (!projectInfo?.warnings.length) {
                continue;
            }
            return [...warnings, warning];
        }
    }

    return warnings;
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        layoutsActions: bindActionCreators(LayoutsStore.actionCreators.forEntity(entityName), dispatch),
        warningsTypeMapsActions: bindActionCreators(WarningsTypeMapsStore.actionCreators, dispatch),
        calendarActions: bindActionCreators(CalendarStore.actionCreators, dispatch),
    }
}

export default connect(
    (state: ApplicationState, ownProp?: RouteComponentProps<{ id: string }>): StateProps => {
        const warningsTypeMap = state.warningsTypeMaps.maps[entityName] || { common: [], targeted: {} }
        const entity = state.portfolios.activeEntity && state.portfolios.activeEntity.id === ownProp!.match.params.id
            ? state.portfolios.activeEntity
            : undefined;
        let warnings: IWarning[] = [];
        let sectionWarningsTypeMap = {};
        if (entity?.sections) {
            warnings = ensureProjectWarning([...entity.warnings], entity, state);
            const tuple = warningService.filterByEntityConfiguration(warnings, warningsTypeMap, entity.sections);
            warnings = tuple.warnings;
            sectionWarningsTypeMap = tuple.sectionWarningsTypeMap;
        }

        const portfolioFields = state.fields[EntityType.Portfolio];
        const programFields = state.fields[EntityType.Program];
        const projectFields = state.fields[EntityType.Project];
        const keyDateFields = state.fields[EntityType.KeyDate];
        const riskFields = state.fields[EntityType.Risk];

        return {
            tenant: state.tenant,
            user: state.user,
            portfolioFields: portfolioFields.allIds.map(_ => portfolioFields.byId[_]),
            programFields: programFields.allIds.map(_ => programFields.byId[_]),
            projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
            keyDateFields: keyDateFields.allIds.map(_ => keyDateFields.byId[_]),
            riskFields: riskFields.allIds.map(_ => riskFields.byId[_]),
            entity,
            warnings,
            warningsTypeMap,
            sectionWarningsTypeMap,
            projects: state.projectsList.allIds.map(_ => state.projectsList.byId[_]),
            programs: state.programs.allIds.map(_ => state.programs.byId[_]),
            isLoading: state.portfolios.isLoading || state.warningsTypeMaps.isLoading || !!state.layouts[entityName].isApplyingLayout,
            portfolioReports: state.tenant.reporting.portfolioReports.subPacks,
            insights: state.tenant.insights,
            isUpdatingSections: state.portfolios.isUpdatingSections,
            layouts: state.layouts[entityName],
            isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
        };
    },
    mergeActionCreators
)(PortfolioDetails);
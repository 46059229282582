import * as React from 'react';
import * as analytics from '../../../../analytics';
import { IControlConfiguration, ISectionUIControlProps } from "../../interfaces/ISectionUIControlProps";
import {
    IRoadmapItem, IRoadmapItemAttrs, IRoadmapItemDependency, RoadmapItemType, RoadmapItemTypeMap,
    urlParamsBuilder, ISubentitySectionActions, ImportRendererProps
} from '../../../../entities/Subentities';
import { UserState } from "../../../../store/User";
import * as Notifications from "../../../../store/NotificationsStore";
import { connect } from 'react-redux';
import { ApplicationState } from "../../../../store";
import { withRouter, RouteComponentProps } from "react-router-dom";
import EntityGroupHeader, { EntityGroup } from '../../extensibleEntity/EntityGroupHeader';
import {
    BaseFilterValue, Field, FormatType, Group, IBaseGroupInfo, IFilter, IListSubView, ITimelineSubView, NEW_ID, PreFilterOption, RoadmapItemPrefilterKey,
    Option
} from '../../../../entities/Metadata';
import { Dictionary, EntityType, IPatch, IUserInfo, ServerEntityType, TimelineMapControlSettings } from '../../../../entities/common';
import SubentitiesList, { ISubentitiesListSettings, SelectedEntitiesActions, SubListControlSettings, SubentitiesListHierarchyContext } from '../../SubentitiesList';
import { Grouping, IRow, IStyleSettings, Styling, } from '../../timeline/TimelineList';
import { ControlSettingsUpdate, nameof, namesof } from '../../../../store/services/metadataService';
import GroupSelectionPanel from './tasksControl/GroupSelectionPanel';
import { GroupInfo } from '../../CreateGroupPanel';
import { ITimelineMapMarker, ITimelineMapProps, ITimelineMapSegment, Ranking } from '../../extensibleEntity/EntityTimelineMap';
import {
    buildMapSegment, buildMapMarker, renderSegmentContent, renderMarkerContent, calculateRoadmapGroupSummary, renderLaneTooltipContent,
    getListViewGroupRow, getTimelineMapGroupRow, buildMapRelation, isMilestone, IRoadmapStyleSettingValues
} from '../../../roadmap/map';
import { IContextualMenuItem, MessageBar, MessageBarType, TooltipDelay, TooltipHost } from 'office-ui-fabric-react';
import { IBaseTimelineElement, ITimelineSegment } from '../../timeline/TimelineSegment';
import { ITimelineMarker } from '../../timeline/TimelineMarker';
import RoadmapItemTooltipContent from '../../../roadmap/RoadmapItemTooltipContent';
import RoadmapItemUpdatePanel from '../../../roadmap/RoadmapItemUpdatePanel';
import RemoveDialog from '../../RemoveDialog';
import { FilterHelper, FilterHelperProps } from '../../../../store/roadmapItem/filters';
import { diffDays } from '../../timeline/utils';
import { toDate, arraysEqual, toDictionaryById, notUndefined, formatValue } from '../../../utils/common';
import { RankService } from '../../../utils/RankService';
import { bindActionCreators } from 'redux';
import { ProgressIcon } from '../../../common/ProgressFormatter';
import PersonPickerInput from '../../inputs/PersonPickerInput';
import {
    DefaultListGroups, EntityLinkDescriptor, getRoadmapItemIsPlanned, getRoadmapItemPlanState, isPlanned, isRoadmapItemDependency, MilestonesLaneId,
    PlanStateRankStep, setRoadmapItemPlanState
} from '../../../../store/RoadmapsListStore';
import { DialogAction, ISubentityPanelProps } from '../../SubentityPanel';
import { rendersBuilder, validatorBuilder } from '../../../roadmap/Fields';
import { RoadmapItemOrigin } from '../../../roadmap/RoadmapItemOrigin';
import RoadmapItemDependencies from '../../../roadmap/RoadmapItemDependencies';
import { SourceType } from '../../../../store/ExternalEpmConnectStore';
import { ProgressScale } from './TasksControl';
import { SectionHierarchyManager } from '../../../utils/SectionHierarchyManager';
import { SortService } from '../../../../services/SortService';
import SelectionExt from '../../SelectionExt';
import { ITimelineRelation } from '../../timeline/TimelineRelation';
import RoadmapItemCreateFromPanel from '../../../roadmap/RoadmapItemCreateFromPanel';
import InlineAddInput from '../../inputs/InlineAddInput';
import { CalendarDataSet } from '../../../../store/CalendarStore';
import ConfigureRoadmapTooltipPanel, { TooltipFieldSettings, TOOLTIP_SETTINGS } from '../../../roadmap/ConfigureRoadmapTooltipPanel';
import { PivotKeys } from '../../../roadmap/RoadmapTooltipPivotContent';
import { contains, CommonOperations } from '../../../../store/permissions';
import { IWithStyleSettings, PreferenceUpdates, Views } from '../../../../store/services/viewSaver';
import { FieldsState } from '../../../../store/fields';
import { ViewService } from '../../../../services/ViewService';

export interface IDataContext {
    lanes?: Group[] | null;
    labels?: Group[] | null;
}
export type IActions = ISubentitySectionActions<IRoadmapItem> & {
    createLane: (lane: IBaseGroupInfo) => void;
    updateLane: (lane: Group) => void;
    removeLane: (laneId: string) => void;
    reorderLanes: (laneIds: string[]) => void;
    createLabel: (group: IBaseGroupInfo) => void;
    updateLabel: (group: Group) => void;
    removeLabel: (groupId: string) => void;
    updateBulk: (items: IPatch<IRoadmapItem>[], callback?: (items: IRoadmapItem[]) => void) => void;
    updateRoadmapItemsDependencies: (toCreate: IRoadmapItemDependency[], toDeleteIds: string[]) => void;
    createFromRoadmapItem: (descriptor: EntityLinkDescriptor, callback: (roadmapItem: IRoadmapItem) => void) => void;
    partialUpdateUiControl: (sectionId: string, uiControlId: string, updates: ControlSettingsUpdate) => void;
}

type ActionProps = {
    notificationsActions: typeof Notifications.actionCreators;
};

type IRoadmItemsControlSettings = ISubentitiesListSettings & {
    tooltipSettings?: {
        lane: TooltipFieldSettings;
        bar: TooltipFieldSettings;
        keydate: TooltipFieldSettings;
    }
};
type RoadmapTimelineMapControlSettings = TimelineMapControlSettings
    & IWithStyleSettings<IRoadmapStyleSettingValues>;

type ControlSettings = SubListControlSettings & {
    timelineMap: RoadmapTimelineMapControlSettings;
}
type TEntity = { id: string, roadmapItems: IRoadmapItem[], roadmapItemsDependencies: IRoadmapItemDependency[] }

export type IConfiguration = IControlConfiguration<IActions, ControlSettings, IDataContext>;
type OwnProps = ISectionUIControlProps<IActions, IRoadmItemsControlSettings, TEntity, {}, ControlSettings, IDataContext>;

type StateProps = {
    user: UserState;
    calendar: CalendarDataSet;
    isListLoading: boolean;
    isListUpdating: boolean;
    fields: Field[];
    fieldsState: Dictionary<FieldsState>;
    isTimelineMapView: boolean;
    lanes: Group[];
    labels: Group[];
    selectedEntityId?: string;
    selectedItemType?: RoadmapItemType;
    activeListSubView?: IListSubView | ITimelineSubView;
};

type Props = OwnProps & StateProps & ActionProps & RouteComponentProps<{}>;

type ItemDetailsDialogProps = {
    action: DialogAction;
    selectedEntityId: string;
    isPlanned: boolean;
    dependencies: IRoadmapItemDependency[];
    type?: RoadmapItemType;
};

type State = {
    labelsMap: Dictionary<Group>;
    itemGroups: EntityGroup[] | undefined;
    roadmapItems: IRoadmapItem[];
    filteredRoadmapItems: IRoadmapItem[];
    preFilterItems: PreFilterOption<IRoadmapItem>[];
    roadmapItemsDependenciesMap: Dictionary<IRoadmapItemDependency[]>;
    readOnly?: boolean;
    showManageLanes?: boolean;
    showManageLabels?: boolean;
    itemDetailsDialogProps?: ItemDetailsDialogProps;
    roadmapItemIdsToRemove?: string[];
    importDialogProps?: {
        selectedEntityType?: ServerEntityType;
    };
    createDependencyItemId?: string;
    updateRoadmapItemsProps?: { ids: string[] };
    createFrom?: { roadmapItem: IRoadmapItem };
    showConfigureTooltipPanel?: boolean;
};

const SubList = SubentitiesList<IRoadmapItem>();

type RoadmapStyling = Styling<IRoadmapStyleSettingValues>

function buildPrefilterItems(): PreFilterOption<IRoadmapItem>[] {
    return [{
        key: RoadmapItemPrefilterKey.withWarnings, name: 'With Warnings', predicate: _ =>
            _.warnings.length > 0
    }];
}

export const defaultStyleSettingValues: IRoadmapStyleSettingValues = {
    largeBars: false,
    showToday: true,
    showDates: true,
    showProgress: true,
    showRelations: true,
    showStatus: false,
    showWarnings: true,
    showAssignments: false,
    showLink: true,
    showBaseline: false,
    showTooltip: true,
}

class RoadmapItemsControl extends React.Component<Props, State> {
    private _hierarchy: SectionHierarchyManager<IRoadmapItem, SubentitiesListHierarchyContext<IRoadmapItem>>;
    private _selection: SelectionExt;
    private _resetSelection = () => this._selection.setAllSelected(false);

    private _roadmapStyleSettings: IStyleSettings<IRoadmapStyleSettingValues> = {
        largeBars: { label: 'Large Bars' },
        showToday: { label: 'Show Today' },
        showDates: { label: 'Show Dates' },
        showProgress: { label: 'Show Progress' },
        showAssignments: { label: 'Show Assigned to' },
        showRelations: { label: 'Show Dependencies' },
        showStatus: { label: 'Show Status' },
        showWarnings: { label: 'Show Warnings' },
        showLink: { label: 'Show Link' },
        showTooltip: {
            label: 'Show Tooltip',
            configureTitle: 'Configure tooltip',
            disableConfigure: () => !this._canUserConfigureTooltip(),
            onConfigure: () => this.setState({ showConfigureTooltipPanel: true })
        }
    };

    constructor(props: Props) {
        super(props);
        this._hierarchy = new SectionHierarchyManager<IRoadmapItem, { filter: IFilter<BaseFilterValue> }>({
            fieldId: props.fields.find(_ => _.name === "Name")?.id!,
            allowDrag: () => true,
            getItemId: (entity) => entity.id,
            getItemParentId: (entity) => null,
            getItemIsParent: (entity) => false,
            isHierarchicalSort: () => false
        });

        this._selection = new SelectionExt({
            onSelectionChanged: this._onSelectionChanged
        });

        this.state = {
            ...this._buildState(props),
            preFilterItems: buildPrefilterItems(),
            importDialogProps: this.props.location.state?.showImportEntityType !== undefined
                ? { selectedEntityType: this.props.location.state.showImportEntityType }
                : undefined,
        };
    }

    componentWillMount() {
        this.setState(this._buildState(this.props));
    }

    componentWillReceiveProps(nextProps: Props) {
        if (!arraysEqual(this.props.entity.roadmapItems, nextProps.entity.roadmapItems)
            || !arraysEqual(this.props.entity.roadmapItemsDependencies, nextProps.entity.roadmapItemsDependencies)
            || !arraysEqual(this.props.lanes, nextProps.lanes)
            || !arraysEqual(this.props.labels, nextProps.labels)
            || this.props.entity.isEditable !== nextProps.entity.isEditable
            || this.props.isTimelineMapView !== nextProps.isTimelineMapView
            || this.props.selectedEntityId !== nextProps.selectedEntityId) {
            this.setState(this._buildState(nextProps));
        }
    }

    private _buildState(props: Props) {
        const { isTimelineMapView, lanes } = props;
        const roadmapItems = props.entity.roadmapItems;
        const labelsMap = toDictionaryById(props.labels);
        const lanesMap = toDictionaryById(props.lanes);
        const filteredRoadmapItems = isTimelineMapView
            ? roadmapItems.filter(_ => getRoadmapItemIsPlanned(_)
                && lanesMap[_.attributes.Lane.id]?.isShown
                && (!_.attributes.Label || labelsMap[_.attributes.Label.id]?.isShown))
            : roadmapItems;

        const roadmapItemsDependenciesMap = props.entity.roadmapItemsDependencies
            .reduce((map, dependency) => ({
                ...map,
                //same nail as in tasks dependencies
                //needed for correct rendering svg during export to png                    
                [dependency.sourceId]: [...(map[dependency.sourceId] ?? []), dependency],
                [dependency.targetId]: [...(map[dependency.targetId] ?? []), dependency]
            }), {});

        return {
            readOnly: !props.entity.isEditable,
            roadmapItems,
            filteredRoadmapItems,
            roadmapItemsDependenciesMap,
            labelsMap,
            itemGroups: this._buildItemGroups(filteredRoadmapItems, lanes, isTimelineMapView),
            itemDetailsDialogProps: this._buildItemDetailsDialogProps(props)
        };
    }

    private _buildItemGroups = (items: IRoadmapItem[], lanes: Group[], isTimelineMapView: boolean): EntityGroup[] =>
        isTimelineMapView
            ? this._buildTimelineMapGroups(items, lanes)
            : this._buildDetailsGroups(items);

    private _buildItemDetailsDialogProps(props: Props) {
        const { selectedEntityId, selectedItemType } = props;
        if (!selectedEntityId) {
            return undefined;
        }

        return {
            action: selectedEntityId.toLowerCase() === NEW_ID ? DialogAction.Add : DialogAction.Update,
            selectedEntityId,
            type: selectedItemType ?? RoadmapItemType.Bar,
            isPlanned: true,
            dependencies: this._getRelatedDependencies(props.entity, selectedEntityId)
        };
    }

    private _getRelatedDependencies = (roadmap: { roadmapItemsDependencies: IRoadmapItemDependency[] }, selectedEntityId: string) =>
        roadmap.roadmapItemsDependencies.filter(_ => _.sourceId === selectedEntityId || _.targetId === selectedEntityId);

    private _buildTimelineMapGroups(roadmapItems: IRoadmapItem[], lanes: Group[]): EntityGroup[] {
        const itemsByLaneId: Dictionary<IRoadmapItem[]> = roadmapItems
            .reduce((result: Dictionary<IRoadmapItem[]>, item: IRoadmapItem) => ({
                ...result,
                [item.attributes.Lane.id]: [...(result[item.attributes.Lane.id] || []), item],
            }), {});
        return lanes
            .filter(_ => _.isShown)
            .map<EntityGroup>(_ => ({
                key: _.id,
                name: _.name,
                color: _.color,
                count: (itemsByLaneId[_.id] || []).length,
                data: calculateRoadmapGroupSummary(itemsByLaneId[_.id] || []),
                hideMarker: true
            }));
    }

    private _buildDetailsGroups(roadmapItems: IRoadmapItem[]): EntityGroup[] {
        const itemsByCategory: Dictionary<IRoadmapItem[]> = roadmapItems
            .reduce((result: Dictionary<IRoadmapItem[]>, item: IRoadmapItem) => ({
                ...result,
                [getRoadmapItemPlanState(item)]: [...(result[getRoadmapItemPlanState(item)] || []), item],
            }), {});
        return DefaultListGroups
            .map<EntityGroup>(_ => ({
                key: _.id,
                name: _.name,
                color: _.color,
                count: (itemsByCategory[_.id] || []).length,
                data: calculateRoadmapGroupSummary(itemsByCategory[_.id] || []),
                hideMarker: true
            }));
    }

    private _addOrUpdateRoadmapItems(roadmapItems: IRoadmapItem[], changedItems: IRoadmapItem[]): IRoadmapItem[] {
        const itemsMap = toDictionaryById(roadmapItems.filter(_ => !!_.id));
        const newItems = changedItems.filter(_ => !_.id || !itemsMap[_.id]);
        const changedMap = toDictionaryById(changedItems.filter(_ => !!_.id));
        return [...roadmapItems?.map(_ => changedMap[_.id] || _), ...newItems];
    }

    private _addOrUpdateRoadmapItemsInState = (items: IRoadmapItem[]) => {
        const roadmapItems = this._addOrUpdateRoadmapItems(this.state.roadmapItems, items);
        const filteredRoadmapItems = this._addOrUpdateRoadmapItems(this.state.filteredRoadmapItems, items)
            .filter(_ => !this.props.isTimelineMapView || getRoadmapItemIsPlanned(_));

        this.setState({
            roadmapItems,
            filteredRoadmapItems,
            itemGroups: this._buildItemGroups(filteredRoadmapItems, this.props.lanes, this.props.isTimelineMapView)
        });
    }

    render() {
        const { id, sectionId, entity, isListLoading, isListUpdating, settings, fields, actions, labels, lanes, isTimelineMapView,
            controlSettings, onSaveSettings, isFullScreen } = this.props;
        const { readOnly, showManageLabels, showManageLanes, itemDetailsDialogProps, roadmapItemIdsToRemove, itemGroups,
            importDialogProps, filteredRoadmapItems, createDependencyItemId, updateRoadmapItemsProps, createFrom, showConfigureTooltipPanel } = this.state;

        if (!lanes) {
            return null;
        }

        const ranking = this._buildRanking();
        const timelineMapProps: ITimelineMapProps = {
            type: 'TimelineMap',
            styling: {
                settings: this._roadmapStyleSettings,
                values: controlSettings.timelineMap.styleSettingValues,
                onChange: (key, value) => onSaveSettings?.(PreferenceUpdates.viewType(Views.TimelineMap, PreferenceUpdates.style(key, value)))
            },
            renderSegmentContent,
            renderMarkerContent,
            buildSegment: (item: IRoadmapItem, styling: RoadmapStyling) => buildMapSegment(item, fields, styling.values),
            buildMarker: (item: IRoadmapItem, styling: RoadmapStyling) => buildMapMarker(item, fields, styling.values),
            buildRelations: (item: IRoadmapItem, itemsMap: Dictionary<IRoadmapItem>, styling: RoadmapStyling) =>
                this.state.roadmapItemsDependenciesMap[item.id]
                    ?.map(_ => buildMapRelation(item, _, itemsMap, fields, styling.values))
                    .filter(notUndefined),
            segmentDragAxis: () => "both",
            markerDragAxis: () => "both",
            onSegmentChange: readOnly ? undefined : this._onSegmentChange,
            onMarkerChange: readOnly ? undefined : this._onMarkerChange,
            onSegmentDoubleClick: (row, segment) => segment.entity?.id && this._editRoadmapItem(segment.entity.id),
            onMarkerDoubleClick: (row, marker) => marker.entity?.id && this._editRoadmapItem(marker.entity.id),
            grouping: this._buildTimelineMapGrouping(),
            renderSegmentTooltipContent: controlSettings.timelineMap.styleSettingValues.showTooltip ? this._renderRoadmapItemTooltipContent : undefined,
            renderMarkerTooltipContent: controlSettings.timelineMap.styleSettingValues.showTooltip ? this._renderRoadmapItemTooltipContent : undefined,
            ranking,
            buildSegmentCommands: this._buildTimelineElementCommands,
            buildMarkerCommands: this._buildTimelineElementCommands,
            buildRelationCommands: this._buildTimelineRelationCommands
        };

        const entityPanelProps: Partial<ISubentityPanelProps<IRoadmapItem>> = {
            subentityTypeLabel: (item: IRoadmapItem) => RoadmapItemTypeMap[item.attributes.Type],
            secondaryMessage: (item: IRoadmapItem) => item.externalData?.["ImportedFromName"]
                && <MessageBar>
                    Source: <RoadmapItemOrigin entity={item} />
                </MessageBar>,
            subentityType: EntityType.RoadmapItem,
            fields: (item: IRoadmapItem) => this._getFilteredFields(item.attributes.Type),
            className: (item: IRoadmapItem) => item.attributes.Type === RoadmapItemType.KeyDate ? 'key-date' : 'bar',
            displayFields: settings.timelineMap?.displayFields ?? [],
            mandatoryEditFields: this._buildMandatoryFields(this._getFilteredFields(itemDetailsDialogProps?.type)),
            buildNewEntity: () => this._buildNew(itemDetailsDialogProps!.type!, isTimelineMapView),
            uiControlElementsCustomRender: rendersBuilder(lanes, labels, this.props.fieldsState),
            customFieldValidatorBuilder: validatorBuilder,
            extraTabs: [{
                key: 'dependencies',
                label: 'Dependencies',
                renderHeaderSecondaryText: (item: IRoadmapItem) => `Assign ​dependencies to the ${RoadmapItemTypeMap[item.attributes.Type]}`,
                renderBody: this._renderDependencies
            }]
        };
        return <>
            <SubList
                isFullScreen={isFullScreen}
                sectionId={this.props.sectionId}
                controlId={this.props.id}
                parentEntityId={this.props.entity.id}
                timelineMapProps={timelineMapProps}
                groups={itemGroups}
                listGrouping={this._buildDetailsViewGrouping()}
                readOnly={!!readOnly}
                subentityInfo={{
                    subentityType: EntityType.RoadmapItem,
                    subentityTypeLabel: "Roadmap Item",
                    subentityCollectionName: nameof("roadmapItems", entity)
                }}
                showOnlyExternalCommands
                externalCommands={{
                    actionsCommandItems: this._buildActionsCommandItems,
                    commands: this._buildCommands,
                    selectionModeCommands: this._buildSelectionModeCommands,
                }}
                commandsConfig={{
                    export: { hidden: isTimelineMapView },
                    import: { hidden: isTimelineMapView },
                    aiGeneration: { hidden: true }
                }}
                controlSettings={controlSettings}
                onSaveSettings={onSaveSettings}
                useFilters
                preFilterItems={this.state.preFilterItems}
                useViews={!isTimelineMapView}
                sourceType={SourceType.Ppmx}
                entities={filteredRoadmapItems}
                entitiesIsLoading={isListLoading}
                entitiesIsUpdating={isListUpdating}
                settings={settings}
                actions={{
                    ...this.props.actions,
                    create: this._create,
                    update: this._update
                }}
                buildEntityFilterHelper={this._buildFilterHelper}
                entityPanelProps={entityPanelProps}
                onEditMenuClick={(action: DialogAction, subentity?: IRoadmapItem) => {
                    if (action === DialogAction.Update && subentity?.id) {
                        this.setState({
                            itemDetailsDialogProps: {
                                action,
                                selectedEntityId: subentity?.id,
                                type: subentity.attributes.Type,
                                isPlanned: subentity.externalData.IsPlanned,
                                dependencies: this._getRelatedDependencies(entity, subentity.id)
                            }
                        });
                    }
                }}
                hierarchy={this._hierarchy}
                fieldValueExtractor={(item: IRoadmapItem, field: Field) => {
                    if (field.name === nameof<IRoadmapItemAttrs>("MoSCoW")) {
                        return (field.settings?.options as Option[]).findIndex(_ => _.name === item.attributes.MoSCoW);
                    }
                    return SortService.getFieldValueBaseImpl(field, item, (fld) => !fields.find(_ => _.name === fld.name));
                }}
                selection={this._selection}
                menu={{
                    isEnabled: true,
                    mandatoryFields: ViewService.getViewMandatoryFields(EntityType.LessonLearned),
                    getItemCommands: this._buildItemCommands,
                }}
            />
            {showManageLanes && lanes &&
                <GroupSelectionPanel
                    groups={lanes}
                    entityName={{ singular: "Lane", plural: "Lanes" }}
                    allowCreateGroups={entity.isEditable}
                    allowEditGroups={entity.isEditable}
                    onDismiss={() => this.setState({ showManageLanes: false })}
                    saveGroup={this.saveLane}
                    removeGroup={this.removeLane}
                    reorderGroups={isTimelineMapView ? this.reorderLanes : undefined}
                    itemClassName={isTimelineMapView ? undefined : "with-hover"}
                    removeDialog={{
                        messageBuilder: (removeLane: Group) => `Are you sure you want to delete lane "${removeLane.name}"?`,
                        contentBuilder: (removeLane: Group) => <div className="message-bar">
                            <MessageBar messageBarType={MessageBarType.warning}>
                                The items located in this lane will be deleted as well.
                            </MessageBar>
                        </div>
                    }}
                    hideToggle={!isTimelineMapView}
                />}
            {showManageLabels &&
                <GroupSelectionPanel
                    groups={labels}
                    hideIsDefault
                    entityName={{ singular: "Label", plural: "Labels" }}
                    allowCreateGroups={entity.isEditable}
                    allowEditGroups={entity.isEditable}
                    allowDeleteLastItem
                    onDismiss={() => this.setState({ showManageLabels: false })}
                    saveGroup={this.saveLabel}
                    removeGroup={this.removeLabel}
                    itemClassName="with-hover"
                    removeDialog={{
                        messageBuilder: (removeGroup: Group) => `Are you sure you want to delete label "${removeGroup.name}"?`,
                        contentBuilder: (removeGroup: Group) => <div className="message-bar">
                            <MessageBar messageBarType={MessageBarType.warning}>
                                The group color will be applied to all items with no label.
                            </MessageBar>
                        </div>
                    }}
                    hideToggle={!isTimelineMapView}
                />}
            {roadmapItemIdsToRemove && !itemDetailsDialogProps && this._renderRemoveDialog()}
            {importDialogProps && actions.renderImport?.({
                onDismiss: this._onSubentityPanelClosed,
                selectedEntityType: importDialogProps.selectedEntityType,
                viewType: controlSettings.viewType
            } as ImportRendererProps)}
            {createDependencyItemId && <div className="message-selection-mode">Select an item to create dependency</div>}
            {updateRoadmapItemsProps && this._renderUpdateRoadmapItemsPanel()}
            {createFrom && <RoadmapItemCreateFromPanel
                entity={createFrom.roadmapItem}
                onDismiss={() => this.setState({ createFrom: undefined })}
                onComplete={this.props.actions.createFromRoadmapItem}
            />}
            {showConfigureTooltipPanel && (
                <ConfigureRoadmapTooltipPanel
                    settings={settings[TOOLTIP_SETTINGS]}
                    onChanged={_ => actions.partialUpdateUiControl(sectionId, id, _)}
                    onDismiss={() => this.setState({ showConfigureTooltipPanel: false })}
                />)}
        </>;
    }

    private _getFilteredFields = (itemType?: RoadmapItemType): Field[] => {
        const fieldsToFilter = [
            nameof<IRoadmapItemAttrs>("Type"),
            itemType === RoadmapItemType.KeyDate ? nameof<IRoadmapItemAttrs>("StartDate") : undefined
        ].filter(notUndefined);
        return this.props.fields.filter(_ => !fieldsToFilter.includes(_.name));
    }

    private _onSelectionChanged = (): void => {
        const selected = this._selection.getSelection() as IRoadmapItem[];
        this.setState({ createDependencyItemId: undefined });
        if (this.state.createDependencyItemId && selected.length === 1) {
            this._createDependency(this.state.createDependencyItemId, selected[0].id);
            this._resetSelection();
        }
    }

    private _createDependency = (sourceId: string, targetId: string) => {
        const { roadmapItemsDependencies } = this.props.entity;

        if (sourceId === targetId) {
            return;
        }

        if (roadmapItemsDependencies.find(_ => _.sourceId === sourceId && _.targetId === targetId || _.sourceId === targetId && _.targetId === sourceId)) {
            this.props.notificationsActions.pushNotification({ type: Notifications.NotificationType.Warn, message: "The items are already linked." });
            return;
        }

        this._saveDependencies(this.props.id, [{ sourceId: sourceId, targetId: targetId } as IRoadmapItemDependency]);
        this.props.notificationsActions.pushNotification({ message: `Dependency is created.` });
    }

    private _buildMandatoryFields = (fields: Field[]): string[] => {
        const { activeListSubView } = this.props;

        const result = namesof<IRoadmapItemAttrs>(["Name", "Lane", "Label", "StartDate", "FinishDate", "Status", "Progress", "AssignedTo", "Description"]);

        if (activeListSubView?.name) {
            activeListSubView.columns.forEach(col => {
                const field = fields.find(_ => _.id === col.id);
                field && !result.includes(field.name) && result.push(field.name);
            });
        }

        return result;
    }

    private _calculatePlannedRank(isplanned: boolean): number {
        const { entity } = this.props;
        const maxRank = Math.max(
            ...entity.roadmapItems
                .filter(_ => _.externalData.IsPlanned === isplanned)
                .map(_ => _.externalData.PlannedRank));

        return maxRank + PlanStateRankStep;
    }

    private _buildNew = (itemType: RoadmapItemType, planned: boolean): IRoadmapItem => {
        const { actions } = this.props;

        const item = actions.buildNew?.() ?? {} as IRoadmapItem;
        item.attributes.Type = itemType;
        setRoadmapItemPlanState(item, planned);

        const milestonesLane = itemType === RoadmapItemType.KeyDate ? this.props.lanes?.find(_ => _.id === MilestonesLaneId) : undefined;
        const lane = milestonesLane ?? this.props.lanes?.find(_ => _.isDefault);
        item.attributes.Lane = lane!;

        item.externalData.PlannedRank = this._calculatePlannedRank(planned);
        item.warnings = [];

        return item;
    }

    private _buildFilterHelper = (props: FilterHelperProps) => {
        return new FilterHelper({ ...props, lanes: this.props.lanes || [], labels: this.props.labels || [] });
    }

    private _renderDependencies = (roadmapItem: IRoadmapItem, onChange?: () => void) => {
        return <RoadmapItemDependencies
            readonly={!this.props.entity.isEditable}
            roadmapItemId={roadmapItem.id}
            roadmapItems={this.props.entity.roadmapItems}
            dependencies={this.state.itemDetailsDialogProps!.dependencies}
            add={(dependency: IRoadmapItemDependency) => {
                onChange?.();
                this.setState({
                    itemDetailsDialogProps: {
                        ...this.state.itemDetailsDialogProps!,
                        dependencies: [...this.state.itemDetailsDialogProps!.dependencies, dependency]
                    }
                });
            }}
            remove={(dependency: IRoadmapItemDependency) => {
                onChange?.();
                this.setState({
                    itemDetailsDialogProps: {
                        ...this.state.itemDetailsDialogProps!,
                        dependencies: this.state.itemDetailsDialogProps!.dependencies.filter(_ => _ !== dependency)
                    }
                });
            }}
        />
    };

    private _renderUpdateRoadmapItemsPanel = () => {
        const { updateRoadmapItemsProps } = this.state;
        const items = this.state.roadmapItems.filter(_ => updateRoadmapItemsProps!.ids.includes(_.id));
        return <RoadmapItemUpdatePanel
            user={this.props.user}
            items={items}
            onDismiss={() => this.setState({ updateRoadmapItemsProps: undefined })}
            onUpdate={(changedItems: IRoadmapItem[]) => {
                this._saveItems(changedItems);
                this._resetSelection();
            }} />
    }

    private _saveDependencies = (roadmapItemId: string, dependencies: IRoadmapItemDependency[]) => {
        const toCreate = dependencies.filter(_ => !_.id);
        toCreate.filter(_ => !_.sourceId).forEach(_ => _.sourceId = roadmapItemId);
        toCreate.filter(_ => !_.targetId).forEach(_ => _.targetId = roadmapItemId);
        const toSaveMap = toDictionaryById(dependencies);
        const toRemove = this.props.entity.roadmapItemsDependencies
            .filter(_ => (_.sourceId === roadmapItemId || _.targetId === roadmapItemId) && !toSaveMap[_.id])
            .map(_ => _.id);
        if (toCreate.length > 0 || toRemove.length > 0) {
            this.props.actions.updateRoadmapItemsDependencies(toCreate, toRemove);
        }
    }

    private _renderRemoveDialog = () => {
        const { roadmapItemIdsToRemove } = this.state;
        const items = this.state.roadmapItems.filter(_ => roadmapItemIdsToRemove?.includes(_.id));
        if (!items.length) {
            return null;
        }
        return <RemoveDialog
            onClose={() => this.setState({ roadmapItemIdsToRemove: undefined })}
            onComplete={() => {
                this.setState({ itemDetailsDialogProps: undefined, roadmapItemIdsToRemove: undefined });
                this.props.actions.remove(items.map(_ => _.id));
                this._resetSelection();
            }}
            confirmButtonProps={{ text: "Delete" }}
            dialogContentProps={{
                title: `Delete ${items.length === 1 ? RoadmapItemTypeMap[items[0].attributes.Type] : "items"}`,
                subText: 'Are you sure you want to delete ' +
                    (items.length === 1
                        ? `${RoadmapItemTypeMap[items[0].attributes.Type]} '${items[0].attributes.Name}'`
                        : `${items.length} items`) + '?'
            }} />
    }

    private _onSegmentChange = (segment: ITimelineMapSegment, delta: Partial<ITimelineMapSegment>) => {
        const item = segment.entity as IRoadmapItem;
        const patch: Partial<IRoadmapItemAttrs> = {};
        const rankPatch: Partial<Dictionary<any>> = {};
        if (delta.rank) {
            rankPatch.Rank = delta.rank;
        }
        if (delta.groupKey) {
            const lane = this.props.lanes.find(_ => _.id === delta.groupKey)!;
            patch.Lane = { id: lane.id, name: lane.name, color: lane.color }
        }
        if (delta.startDate) {
            patch.StartDate = delta.startDate.toDateOnlyString();
        }
        if (delta.finishDate) {
            patch.FinishDate = delta.finishDate.getBeginOfDay().toDateOnlyString();
        }

        this._saveItems([{ ...item, attributes: { ...item.attributes, ...patch }, externalData: { ...item.externalData, ...rankPatch } }]);
    }

    private _onMarkerChange = (marker: ITimelineMapMarker, delta: Partial<ITimelineMapMarker>) => {
        const item = marker.entity as IRoadmapItem;
        const patch: Partial<IRoadmapItemAttrs> = {};
        const rankPatch: Partial<Dictionary<any>> = {};
        if (delta.rank) {
            rankPatch.Rank = delta.rank;
        }
        if (delta.groupKey) {
            const lane = this.props.lanes?.find(_ => _.id === delta.groupKey)!;
            patch.Lane = { id: lane.id, name: lane.name, color: lane.color }
        }
        if (delta.date) {
            if (item.attributes.StartDate) {
                const duration = Math.round(diffDays(toDate(item.attributes.StartDate)!, toDate(item.attributes.FinishDate)!));
                patch.StartDate = delta.date.clone().addDays(-duration).toDateOnlyString();
            } else {
                patch.StartDate = delta.date.toDateOnlyString();
            }

            patch.FinishDate = delta.date.toDateOnlyString();
        }

        this._saveItems([{ ...item, attributes: { ...item.attributes, ...patch }, externalData: { ...item.externalData, ...rankPatch } }]);
    }

    private _getLaneRank = (entity: IRoadmapItem) => entity.externalData.Rank;
    private _setLaneRank = (entity: IRoadmapItem, rank: number) => (entity.externalData.Rank = rank);

    private _getLaneKey = (entity: IRoadmapItem): string => entity.attributes.Lane.id;
    private _getPlanStateKey = (entity: IRoadmapItem): string => getRoadmapItemPlanState(entity);

    private readonly _rankLaneService = new RankService<IRoadmapItem>(this._getLaneRank, this._setLaneRank, this._getLaneKey);

    private _buildRanking = (): Ranking => ({
        getRank: this._getLaneRank,
        getRankAfter: (entity: IRoadmapItem) => this._rankLaneService.GetRankAfter(this.state.roadmapItems, entity),
        getOverlappedEntities: (laneId: string, rank: number, startDate: Date, finishDate: Date, key: string) =>
            this._getOverlappedEntities(this.state.roadmapItems, laneId, rank, startDate, finishDate, key)
    });

    private _getOverlappedEntities = (roadmapItems: IRoadmapItem[], laneId: string, rank: number, startDate: Date, finishDate: Date, key: string) =>
        roadmapItems.filter((__: IRoadmapItem) => __.id !== key
            && __.attributes.Lane.id === laneId
            && __.externalData.Rank === rank
            && toDate(__.attributes.Type === RoadmapItemType.Bar ? __.attributes.StartDate : __.attributes.FinishDate)!.getBeginOfDay() <= finishDate.getBeginOfDay()
            && toDate(__.attributes.FinishDate)!.getBeginOfDay() >= startDate.getBeginOfDay()) ?? [];

    private _saveItems = (items: IRoadmapItem[], callback?: (items: IRoadmapItem[]) => void) => {
        if (!items.length) { return; }

        let roadmapItems = [...this.state.roadmapItems];
        let changedItems: IRoadmapItem[] = [];

        items.forEach(item => {
            if (item.attributes.Type === RoadmapItemType.KeyDate) {
                item.attributes.StartDate = null;
            } else {
                item.attributes.StartDate ??= new Date().toDateOnlyString();
            }
            item.attributes.FinishDate ??= new Date().toDateOnlyString();

            if (getRoadmapItemIsPlanned(item)) {
                if (!this._getLaneRank(item)) {
                    this._setLaneRank(item, this._rankLaneService.GetNewRankInGroup(roadmapItems, item));
                }

                const overlappedEntities = this._getOverlappedEntities(
                    roadmapItems,
                    this._getLaneKey(item),
                    this._getLaneRank(item),
                    toDate(item.attributes.Type === RoadmapItemType.Bar ? item.attributes.StartDate : item.attributes.FinishDate)!,
                    toDate(item.attributes.FinishDate)!,
                    item.id);
                if (overlappedEntities.length > 0) {
                    this._setLaneRank(item, this._rankLaneService.GetRankAfter(roadmapItems, item));
                }
            } else {
                this._setLaneRank(item, 0);
            }

            if (this._getLaneRank(item) % 1) {
                const itms = roadmapItems.map(_ => _.id === item.id ? item : _);
                const changed = this._rankLaneService.RecalculateRanksInGroup(itms, item);

                roadmapItems = this._addOrUpdateRoadmapItems(roadmapItems, changed);
                changedItems = this._addOrUpdateRoadmapItems(changedItems, changed);
            } else {
                roadmapItems = this._addOrUpdateRoadmapItems(roadmapItems, [item]);
                changedItems.push(item);
            }
        });

        this._addOrUpdateRoadmapItemsInState(changedItems);
        this.props.actions.updateBulk(changedItems, callback);
        this._track(changedItems);
    }

    private _track = (items: IRoadmapItem[]) => {
        const toTrack = items.filter(_ => !_.id);
        if (!toTrack.length) {
            return;
        }

        toTrack.forEach(_ => {
            const data = { itemTitle: _.attributes.Name, itemType: RoadmapItemTypeMap[_.attributes.Type], parentType: EntityType.Roadmap };
            analytics.trackCreate(this.props.user, data);
        });
    }

    private _onSubentityPanelClosed = () =>
        this.setState({ itemDetailsDialogProps: undefined, importDialogProps: undefined });

    private _create = (item: IRoadmapItem) => {
        const dependencies = this.state.itemDetailsDialogProps!.dependencies;
        const oldItemIds = this.props.entity.roadmapItems.map(_ => _.id);
        this._saveItems([item], (items: IRoadmapItem[]) => {
            const newItem = items.filter(_ => !oldItemIds.includes(_.id))[0];
            this._saveDependencies(newItem.id, dependencies);
        });

        this._resetSelection();
    }

    private _update = (id: string, changes: Dictionary<unknown>) => {
        const dependencies = this.state.itemDetailsDialogProps!.dependencies;
        const item = this.props.entity.roadmapItems.find(_ => _.id === id);
        if (!item) {
            return;
        }

        this._saveItems([{ ...item, attributes: { ...item.attributes, ...changes } }]);
        this._saveDependencies(id, dependencies);
        this._resetSelection();
    }

    private _buildCommands = (filteredItems: IRoadmapItem[]): IContextualMenuItem[] => {
        return [
            {
                key: 'AddItem',
                text: 'New',
                iconProps: { iconName: 'Add' },
                subMenuProps: {
                    items: [
                        {
                            key: 'NewBar',
                            name: `New ${RoadmapItemTypeMap[RoadmapItemType.Bar]}`,
                            iconProps: { iconName: 'Add' },
                            disabled: !this.props.entity.isEditable,
                            onClick: () => this._onCreateClick(RoadmapItemType.Bar)
                        },
                        {
                            key: 'NewKeyDate',
                            name: `New ${RoadmapItemTypeMap[RoadmapItemType.KeyDate]}`,
                            iconProps: { iconName: 'Add' },
                            disabled: !this.props.entity.isEditable,
                            onClick: () => this._onCreateClick(RoadmapItemType.KeyDate)
                        }
                    ]
                }
            }, {
                key: "importRows",
                name: "Import Items",
                iconProps: { iconName: 'PPMXImport' },
                disabled: !this.props.entity.isEditable,
                onClick: () => this._onImportClick()
            }
        ].filter(notUndefined);
    }

    private _buildSelectionModeCommands = (selectedItems: (IRoadmapItem | IRoadmapItemDependency)[], actions: SelectedEntitiesActions): IContextualMenuItem[] => {
        return [
            ...this._buildItemCommands(selectedItems),
            this._buildUpdateItemsCommand(selectedItems),
            actions.exportToCsv,
            {
                key: "deleteItem",
                name: `Delete`,
                iconProps: { iconName: 'Delete' },
                onClick: () => this.setState({ roadmapItemIdsToRemove: selectedItems.map(_ => _.id) }),
                disabled: !this.props.entity.isEditable,
            },
        ].filter(notUndefined);
    }

    private _buildUpdateItemsCommand = (items: (IRoadmapItem | IRoadmapItemDependency)[]) => {
        return {
            key: "updateItems",
            name: "Update",
            iconProps: { iconName: "PPMXUpdate" },
            onClick: () => this.setState({ updateRoadmapItemsProps: { ids: items.map(_ => _.id) } }),
            disabled: !this.props.entity.isEditable
        }
    }

    private _buildItemCommands = (selectedItems: (IRoadmapItem | IRoadmapItemDependency)[]): IContextualMenuItem[] => {
        const { entity } = this.props;
        const counts = selectedItems.reduce((prev, curr) => {
            if (isRoadmapItemDependency(curr)) {
                prev.dependecies++;
            } else if (getRoadmapItemIsPlanned(curr as IRoadmapItem)) {
                prev.planned++;
            } else {
                prev.backlog++;
            }
            return prev;
        }, { backlog: 0, planned: 0, dependecies: 0 });
        
        const selecteditems = selectedItems.filter(_ => !isRoadmapItemDependency(_)) as IRoadmapItem[];
        const disabled = !selecteditems.length || !entity.isEditable;
        
        return [
            !disabled && counts.backlog === 0 && counts.planned > 0 ? {
                key: 'MoveToBacklog',
                name: `Move to Backlog`,
                iconProps: { iconName: 'FabricMovetoFolder' },
                onClick: () => this._moveToPlanState(selecteditems, false)
            } : undefined,
            !disabled && counts.backlog > 0 && counts.planned === 0 ? {
                key: 'MoveToPlanned',
                name: `Move to Planned`,
                iconProps: { iconName: 'FabricMovetoFolder' },
                onClick: () => this._moveToPlanState(selecteditems, true)
            } : undefined,
            !disabled ? {
                key: 'moveToLane',
                name: 'Move to Lane',
                iconProps: { iconName: "Export" },
                subMenuProps: {
                    items: selecteditems.length && entity.isEditable
                        ? this.props.lanes
                            .map(_ => ({
                                key: _.id,
                                name: _.name,
                                className: "group",
                                iconProps: { className: "group-color", style: { backgroundColor: _.color } },
                                onClick: () => this._moveToLane(selecteditems, _)
                            }))
                        : []
                }
            } : undefined,
            !disabled ? {
                key: 'clone',
                name: 'Clone',
                iconProps: { iconName: "Copy" },
                onClick: () => this._clone(selecteditems)
            } : undefined,
            !disabled ? {
                key: 'setProgress',
                name: 'Set progress',
                iconProps: { iconName: "Completed" },
                subMenuProps: {
                    items: selecteditems.length && entity.isEditable
                        ? ProgressScale.map(_ => (
                            {
                                key: _.toString(),
                                name: formatValue(_, FormatType.Percent),
                                className: "progress-formatter",
                                onRenderIcon: () => <ProgressIcon progress={_} />,
                                iconProps: {},
                                onClick: () => this._setProgress(selecteditems, _)
                            }))
                        : []
                }
            } : undefined,
            !disabled ? {
                key: 'assignTo',
                name: 'Assign to',
                iconProps: { iconName: "PPMXResource" },
                subMenuProps: {
                    items: selecteditems.length && entity.isEditable
                        ? [{
                            key: "personPicker",
                            onRender: (item: any, dismissMenu: (ev?: any, dismissAll?: boolean) => void) => <div style={{ maxWidth: '280px' }}>
                                <PersonPickerInput
                                    inputProps={{ placeholder: selecteditems[0]?.attributes.AssignedTo?.length ? undefined : 'Select resource' }}
                                    value={selecteditems[0]?.attributes.AssignedTo}
                                    multichoice={true}
                                    onChanged={(value) => { this._assignTo(selecteditems, value); dismissMenu(undefined, true); }}
                                    searchUrl="api/resource/find" /></div>
                        }]
                        : []
                }
            } : undefined,
        ].filter(notUndefined);
    }

    private _buildActionsCommandItems = (filteredItems: IRoadmapItem[]): IContextualMenuItem[] => {
        return [
            this._buildUpdateItemsCommand(filteredItems),
            {
                key: "ManageLanes",
                name: `Manage Lanes`,
                iconProps: { iconName: 'GroupList' },
                onClick: this.clickManageLanes,
                disabled: !this.props.entity.isEditable
            },
            {
                key: "ManageLabels",
                name: `Manage Labels`,
                iconProps: { iconName: 'DoubleBookmark' },
                onClick: this.clickManageLabels,
                disabled: !this.props.entity.isEditable
            },
        ].filter(notUndefined);
    }

    private _buildTimelineElementCommands = (element: IBaseTimelineElement): IContextualMenuItem[] | undefined => {
        if (!element?.entity || this.state.createDependencyItemId) {
            return undefined;
        }

        const roadmapItem = element.entity as IRoadmapItem;

        return [
            this.props.entity.isEditable ? {
                key: 'delete',
                title: "Delete",
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    if (element.entity?.id) {
                        this.setState({ roadmapItemIdsToRemove: [element.entity.id] });
                    }
                }
            } : undefined,
            this.props.entity.isEditable && !this._isImported(roadmapItem) ? {
                key: 'createFrom',
                title: `Create from ${RoadmapItemTypeMap[roadmapItem.attributes.Type]}`,
                iconProps: { iconName: 'CircleAddition' },
                onClick: () => {
                    if (roadmapItem?.id) {
                        this.setState({ createFrom: { roadmapItem } });
                    }
                }
            } : undefined,
            this.props.entity.isEditable ? {
                key: 'moveToBacklog',
                title: "Move to Backlog",
                iconProps: { iconName: 'PPMXListView' },
                onClick: () => {
                    this._moveToPlanState([element.entity as IRoadmapItem], false);
                }
            } : undefined,
            this.props.entity.isEditable ? {
                key: 'createDependency',
                title: "Create Dependency",
                iconProps: { iconName: 'DependencyAdd' },
                onClick: () => {
                    this.setState({ createDependencyItemId: element.entity?.id });
                }
            } : undefined,
            this.props.entity.isEditable ? {
                key: 'clone',
                title: "Clone",
                iconProps: { iconName: 'Copy' },
                onClick: () => {
                    this._clone([element.entity as IRoadmapItem]);
                }
            } : undefined,
            {
                key: 'edit',
                title: this.props.entity.isEditable ? "Edit" : "View",
                iconProps: { iconName: this.props.entity.isEditable ? 'Edit' : "View" },
                onClick: () => {
                    this._editRoadmapItem(element.entity?.id);
                }
            },
        ].filter(notUndefined);
    }

    private _isImported = (entity: IRoadmapItem): boolean => !!entity.externalData?.["ImportedFromId"];

    private _buildTimelineRelationCommands = (relation: ITimelineRelation): IContextualMenuItem[] | undefined => {
        if (!relation) {
            return undefined;
        }
        const { entity } = this.props;
        return [
            entity.isEditable ? {
                key: 'delete',
                title: "Delete",
                iconProps: { iconName: 'Delete', style: { color: 'red' } },
                onClick: () => {
                    const toRemove = entity.roadmapItemsDependencies
                        .filter(_ => _.sourceId === relation.parent.entity?.id && _.targetId === relation.child.entity?.id
                            || _.sourceId === relation.child.entity?.id && _.targetId === relation.parent.entity?.id)
                        .map(_ => _.id);
                    this.props.actions.updateRoadmapItemsDependencies([], toRemove);
                }
            } : undefined
        ].filter(notUndefined);
    }

    private _openEditPanel(selectedEntityId: string, type?: RoadmapItemType) {
        const query = new URLSearchParams(this.props.location.search);

        query.set(urlParamsBuilder.item(EntityType.RoadmapItem), selectedEntityId);
        if (type !== undefined) {
            query.set(urlParamsBuilder.itemType(EntityType.RoadmapItem), type.toString());
        }

        this.props.history.replace({
            ...this.props.location,
            search: query.toString()
        });
    }

    private _onImportClick() {
        this.setState({ importDialogProps: {} });
    }

    private _onCreateClick(type: RoadmapItemType) {
        this._openEditPanel(NEW_ID, type);
    }

    private _editRoadmapItem(itemId?: string) {
        if (itemId) {
            this._openEditPanel(itemId);
        }
    }

    private _moveToLane = (selectedItems: IRoadmapItem[], lane: Group) => {
        if (!selectedItems.length) {
            return;
        }

        selectedItems
            .filter(_ => _.attributes.Lane.id !== lane.id)
            .forEach(_ => {
                _.attributes.Lane = lane && { id: lane.id, name: lane.name, color: lane.color };
                this._setLaneRank(_, 0);
            });
        this._saveItems(selectedItems);
        this._resetSelection();
    }

    private _setProgress = (selectedItems: IRoadmapItem[], value: number) => {
        if (selectedItems.length) {
            selectedItems.forEach(_ => { _.attributes.Progress = value; });
            this.props.actions.updateBulk(selectedItems);
            this._resetSelection();
        }
    }

    private _assignTo = (selectedItems: IRoadmapItem[], value: IUserInfo[]) => {
        if (selectedItems.length) {
            selectedItems.forEach(_ => { _.attributes.AssignedTo = value; });
            this.props.actions.updateBulk(selectedItems);
            this._resetSelection();
        }
    }

    private _moveToPlanState = (selectedItems: IRoadmapItem[], planned: boolean) => {
        if (selectedItems.length) {
            selectedItems.forEach(_ => setRoadmapItemPlanState(_, planned));
            this._saveItems(selectedItems);
            this._resetSelection();
        }
    }

    private clickManageLanes = () =>
        this.setState({ showManageLanes: true });

    private clickManageLabels = () =>
        this.setState({ showManageLabels: true });

    private saveLane = (lane: GroupInfo) => {
        if (lane.id) {
            this.props.actions.updateLane(lane as Group);
        } else {
            this.props.actions.createLane(lane as IBaseGroupInfo);
        }
    }

    private removeLane = (lane: Group) =>
        this.props.actions.removeLane(lane.id);

    private reorderLanes = (lanes: Group[]) =>
        this.props.actions.reorderLanes(lanes.map(_ => _.id));

    private saveLabel = (label: GroupInfo) => {
        if (label.id) {
            this.props.actions.updateLabel(label as Group);
        } else {
            this.props.actions.createLabel(label as IBaseGroupInfo);
        }
    }

    private removeLabel = (label: Group) =>
        this.props.actions.removeLabel(label.id);

    private _buildTimelineMapGrouping = (): Grouping => ({
        getGroupRow: (group: EntityGroup, rows: IRow[]): IRow => getTimelineMapGroupRow(group),
        getGroupKey: this._getLaneKey,
        renderGroupHeader: (lane, isCollapsed, isSelected, toggleCollapse, toggleSelect) =>
            <TooltipHost tooltipProps={{
                onRenderContent: this.props.controlSettings.timelineMap.styleSettingValues.showTooltip
                    ? () => renderLaneTooltipContent(lane, this.props.settings?.tooltipSettings?.lane)
                    : undefined,
                maxWidth: "454px"
            }}
                closeDelay={100}
                hostClassName="inline-block"
                delay={TooltipDelay.long}>
                <EntityGroupHeader group={lane}
                    isCollapsed={isCollapsed}
                    isSelected={isSelected}
                    onToggleCollapse={toggleCollapse}
                    onToggleSelect={toggleSelect}
                />
            </TooltipHost>
    });

    private _buildDetailsViewGrouping = (): Grouping => ({
        getGroupRow: (group: EntityGroup, rows: IRow[]): IRow => getListViewGroupRow(group),
        getGroupKey: this._getPlanStateKey,
        renderGroupHeader: (group, isCollapsed, isSelected, toggleCollapse, toggleSelect) =>
            <EntityGroupHeader group={group}
                isCollapsed={isCollapsed}
                isSelected={isSelected}
                onToggleCollapse={toggleCollapse}
                onToggleSelect={toggleSelect}
                renderCounter={group.count ?
                    (grp) => <span className="subitems-counter"
                        title={`${grp.data.counts.complete} item${grp.data.counts.complete === 1 ? '' : 's'} ` +
                            `out of ${grp.count} in the group ${grp.data.counts.complete === 1 ? 'is' : 'are'} completed`}>
                        {grp.data.counts.complete} / {grp.count}
                    </span>
                    : () => <span className="subitems-counter" title={`0 items`}>0</span>}
            />,
        renderGroupFooter: this.state.readOnly ? undefined : this.renderGroupFooter
    });

    private renderGroupFooter = (group: EntityGroup): JSX.Element | null => <div className="with-group-marker group-footer align-center">
        <div className="group-marker" style={{ backgroundColor: group.color }} />
        <div className="container">
            <InlineAddInput
                types={[
                    { label: RoadmapItemTypeMap[RoadmapItemType.Bar], key: RoadmapItemType.Bar },
                    { label: RoadmapItemTypeMap[RoadmapItemType.KeyDate], key: RoadmapItemType.KeyDate }
                ]}
                onSave={(name, key: RoadmapItemType) => this._saveInlineRoadmapItem(name, key, group.key)} />
        </div>
    </div>;

    private _saveInlineRoadmapItem = (name: string, itemType: RoadmapItemType, groupKey: string) => {
        const itemName = name?.trim();
        if (itemName) {
            const newItem = this._buildNew(itemType, isPlanned(groupKey));
            newItem.attributes.Name = itemName;
            this._saveItems([newItem]);
        }
    }

    private _renderRoadmapItemTooltipContent = (row: IRow, segment: ITimelineSegment | ITimelineMarker) => {
        const { fields, user, calendar, settings, controlSettings } = this.props;
        const roadmapItem = segment.entity as IRoadmapItem;
        return (
            <RoadmapItemTooltipContent
                entity={roadmapItem}
                user={user}
                calendar={calendar}
                fields={fields}
                showWarnings={controlSettings.timelineMap.styleSettingValues.showWarnings}
                onEdit={(e: IRoadmapItem) => this._editRoadmapItem(e.id)}
                settings={getTooltipSettings()}
            />
        );

        function getTooltipSettings(): TooltipFieldSettings | undefined {
            const tooltipSettings = settings?.[TOOLTIP_SETTINGS] as Record<PivotKeys, TooltipFieldSettings>;
            return !isMilestone(roadmapItem)
                ? tooltipSettings?.bar
                : tooltipSettings?.keydate;
        }
    }

    private _clone = (items: IRoadmapItem[]) => {
        if (!items.length) { return; }

        const newItems = items.map(item => {
            const defaultNewItem = this._buildNew(item.attributes.Type, getRoadmapItemIsPlanned(item));
            return { ...defaultNewItem, attributes: item.attributes } as IRoadmapItem;
        });

        this._saveItems(newItems);
        this._resetSelection();
        this.props.notificationsActions.pushNotification({
            message: newItems.length > 1
                ? `${newItems.length} roadmap items are cloned`
                : `${RoadmapItemTypeMap[newItems[0].attributes.Type]} '${newItems[0].attributes.Name}' is cloned`
        });
    }

    private _canUserConfigureTooltip(): boolean {
        return this.props.isViewSelected
            ? contains(this.props.user.permissions.common, CommonOperations.ConfigurationManage)
            : !!this.props.entity.canConfigure;
    }
}

function mapStateToProps(state: ApplicationState, props: OwnProps & RouteComponentProps<{}>): StateProps {
    const roadmapItemFields = state.fields[EntityType.RoadmapItem];
    const views = state.views[EntityType.RoadmapItem];
    const query = new URLSearchParams(props.location.search);

    const selectedEntityId = query.get(urlParamsBuilder.item(EntityType.RoadmapItem)) ?? undefined;
    const selectedEntity = props.entity.roadmapItems.find(_ => _.id === selectedEntityId);
    const selectedEntityType = query.get(urlParamsBuilder.itemType(EntityType.RoadmapItem)) ?? undefined;
    const isTimelineMapView = !props.controlSettings || props.controlSettings.viewType === "TimelineMap";
    const viewsStore = isTimelineMapView ? views.timeline : views.list;
    const activeSubViewId = query.get(urlParamsBuilder.view(EntityType.RoadmapItem)) ?? undefined;

    return {
        user: state.user,
        calendar: state.calendar,
        isListLoading: state.roadmaps.isListLoading,
        isListUpdating: state.roadmaps.isLoading,
        fields: roadmapItemFields.allIds.map(_ => roadmapItemFields.byId[_]),
        fieldsState: state.fields,
        isTimelineMapView,
        lanes: props.datacontext.lanes as Group[] ?? [],
        labels: props.datacontext.labels as Group[] ?? [],
        selectedEntityId,
        selectedItemType: selectedEntity?.attributes.Type ?? parseInt(selectedEntityType!),
        activeListSubView: activeSubViewId ? viewsStore.subViews.byId[activeSubViewId] : undefined
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mergeActionCreators)(RoadmapItemsControl));
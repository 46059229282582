import { arraysEqual as FabricArraysEqual, getId } from 'office-ui-fabric-react';
import { Dictionary, EntityType, mapServerEntityType, MaybeDate } from "../../entities/common";
import { FormatType } from "../../entities/Metadata";
import { MouseEventHandler } from "react";
import { UserState } from '../../store/User';
import { CommonOperations, contains } from '../../store/permissions';
import { TenantState } from '../../store/Tenant';
import { ResourcePlanningLevels } from '../settings/tenantSettings/ResourcePlanningSettings';
import { ProjectInfo } from '../../store/ProjectsListStore';

export function FormatDate(data: Date | string | undefined | null, formatOpts?: Intl.DateTimeFormatOptions, culture?: string): string | undefined {
    return FormatDateTime(toDate(data), false, formatOpts, culture);
}

export function FormatDateTime(data: Date | string | undefined | null, time?: boolean, formatOpts?: Intl.DateTimeFormatOptions, culture?: string): string | undefined {
    if (!data) {
        return undefined;
    }

    const date = toDateTime(data)!;
    //https://stackoverflow.com/questions/41679724/why-intl-datetimeformat-produces-different-result-in-different-browsers
    //https://techcommunity.microsoft.com/t5/discussions/bug-intl-datetimeformat-always-uses-en-us-culture/m-p/709751
    //https://stackoverflow.com/questions/44973627/intl-datetimeformat-produces-wrong-format
    culture = culture ?? cultureInfo.name;

    const formatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', ...formatOpts };
    return time
        ? date.toLocaleTimeString(culture, formatOptions)
        : date.toLocaleDateString(culture ,formatOptions);
}

export class DebouncedAction<T = any> {
    private readonly defaultTimeDelay: number = 600;
    private _dataCache: T[] = [];
    private _debouncedAction: () => void;
    private _action: (data: T[]) => void;

    constructor(action: (data: T[]) => void, timeDelay?: number){
        this._action = action;
        this._debouncedAction = waitForFinalEvent(this.executeAction, timeDelay ?? this.defaultTimeDelay, getId());
        this._dataCache = [];
    }

    callAction = (data: T) => {
        this._dataCache.push(data);
        this._debouncedAction();
    }

    private executeAction = () => {
        if (this._dataCache.length) {
            this._action(this._dataCache);
            this._dataCache = [];
        }
    }
}

export const waitForFinalEvent = (() => {
    const timers: { [key: string]: any } = {};
    return (callback: (...args: any[]) => void, ms: number, uniqueId: string) => {
        return (...args: any[]) => {
            if (timers[uniqueId]) {
                clearTimeout(timers[uniqueId]);
            }
            timers[uniqueId] = setTimeout(() => callback(...args), ms);
        }
    };
})();

export function orderNotSelected<T>(items: T[], getIndexInSelection: (i: T) => number, valueExtractor: (a: T) => any, caseinsensetive?: boolean): T[] {
    return [...items]
        .sort((a, b) => {
            const aFirst: number = -1;
            const bFirst: number = 1;

            const aSelectionIndex = getIndexInSelection(a);
            const bSelectionIndex = getIndexInSelection(b);

            const isASelected = aSelectionIndex >= 0;
            const isBSelected = bSelectionIndex >= 0;
            if (isASelected !== isBSelected) {
                return isASelected ? aFirst : bFirst;
            }
            // both selected
            if (isASelected) {
                return aSelectionIndex < bSelectionIndex ? aFirst : bFirst;
            }
            return caseinsensetive
                ? upperFirstSort(valueExtractor(a), valueExtractor(b))
                : valueExtractor(a) <= valueExtractor(b) ? aFirst : bFirst;
        });
}

export function isIntersect(arr1: string[], arr2: string[]) {
    return arr1.filter(_ => arr2.indexOf(_) !== -1).length > 0;
};

export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export function insert<T>(array: T[], index: number, element: T) {
    const arrCopy = [...array];
    arrCopy.splice(index, 0, element);
    return arrCopy;
};

export function filterUnique(list: any[]) {
    return list.filter(distinct);
}

export function isEqual(a: { [key: string]: any }, b: { [key: string]: any }) {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}

export function isDeepEqual(x: { [key: string]: any }, y: { [key: string]: any }) {
    if (x === y) {
        return true;
    }

    if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
        if (Object.keys(x).length !== Object.keys(y).length) {
            return false;
        }

        for (const prop in x) {
            if (!y.hasOwnProperty(prop) || !isDeepEqual(x[prop], y[prop])) {
                return false;
            }
        }

        return true;
    }

    return false;
}

export const arraysEqual = <T extends unknown>(ar1?: T[] | null, ar2?: T[] | null): boolean => (!ar1 && !ar2) || (!!ar1 && !!ar2 && FabricArraysEqual(ar1, ar2))

export function toDictionaryById<T extends { id: string }>(entities: T[]): Dictionary<T> {
    return toDictionaryByKey(entities, 'id');
}

export function toDictionaryByName<T extends { name: string }>(entities: T[]): Dictionary<T> {
    return toDictionaryByKey(entities, 'name');
}

export function toDictionaryByKey<T>(entities: T[], key: keyof T): Dictionary<T> {
    const dictionary = new Dictionary<T>();
    entities.forEach(_ => dictionary[String(_[key])] = _);
    return dictionary;
}  

export function getPersonInfoImageUrl(info: { photo?: string | null, imageId?: string }): string | undefined {
    return info.photo ? base64ToUrl(info.photo) : imageIdToUrl(info.imageId);
}

export function base64ToUrl(photo: string | null | undefined): string | undefined {
    return photo && `data:image;base64,${photo}` || undefined;
}

class BaseUrl {
    private _url: string = document.getElementsByTagName('base')[0].getAttribute('href')!;
    get value(): string {
        return this._url;
    }
    set value(value: string) {
        this._url = value;
    }
}

export const baseUrl = new BaseUrl();

export function imageIdToUrl(imageId: string | null | undefined): string | undefined {
    return imageId ? `${baseUrl.value}/api/image/${imageId}` : undefined;
}

export function urlToImageId(url: string | null | undefined) {
    return url && url.substr(url.lastIndexOf('/') + 1) || undefined;
}

export function formatFieldValue(
    value: string | number | undefined,
    format?: FormatType,
    user?: UserState,
    entityType?: EntityType,
    tenant?: TenantState,
    fieldName?: string
): string {
    return isLockedField(format, fieldName, user, tenant, entityType)
        ? "N/A"
        : formatValue(value, format);
}

export const NotLockedEntityTypes = [EntityType.Challenge, EntityType.Idea, EntityType.Objective, EntityType.Roadmap, EntityType.RoadmapItem];
const ResourcePlanFields = ["PlannedWork", "ActualWork", "EstimatedCost", "EstimatedCharge", "PlannedResourcesCount", "CommittedResourcesCount", "ActualCost"];

export function isLockedField(format?: FormatType, fieldName?: string, user?: UserState, tenant?: TenantState, entityType?: EntityType): boolean {
    const canManageBudget = !user || contains(user.permissions.common, CommonOperations.BudgetManage);
    const budgetLockedEntityType = !entityType || !NotLockedEntityTypes.includes(entityType);
    const resourcePlanningLevel = mapServerEntityType[tenant?.resourcePlanningSettings?.resourcePlanningLevel!]!;
    const restrictedResourcePlanEntityType = !entityType || !resourcePlanningLevel || ResourcePlanningLevels.includes(entityType) && entityType !== resourcePlanningLevel;
    return budgetLockedEntityType && format === FormatType.Cost && !canManageBudget
        || restrictedResourcePlanEntityType && fieldName !== undefined && ResourcePlanFields.includes(fieldName);
}

export function formatValue(value: string | number | undefined, formatType?: FormatType): string {
    if (value === undefined || value === null || value === '') {
        return '';
    }

    const number = Number(value);
    if (Number.isNaN(number)) {
        return value + '';
    }
    
    switch (formatType) {
        case FormatType.Cost: return formatCurrency(number, currency.code);
        case FormatType.Duration: return formatTime(Math.round(sec_per_min * sec_per_min * number));
        case FormatType.Days: return formatDays(number);
        case FormatType.Percent: return Math.round(ten * number) / ten + "%";
        default: return Math.round(number * hundred) / hundred + '';
    }
}

const sec_per_min = 60;
const ten = 10;
const hundred = 100;
const max_3_digit = 999;
const max_4_digit = 9999;
export function shortenNumber(number: number) {
    const sign = number < 0 ? '-' : '';
    number = Math.round(Math.abs(number));
    if (number < max_3_digit) {
        return sign + number;
    }

    if (number < max_4_digit) {
        return `${sign}${Math.round(number / hundred) / ten}K`;
    }

    return `${sign}${Math.round(number / hundred)}K`;
}

function toPercentImpl(value: number, fractionDigits: number = 0): number {
    const multiplicator = fractionDigits > 1 ? (fractionDigits + 1) * 10 : 1;
    return Math.round(value) !== value ? Math.round(value * multiplicator) / multiplicator : value;
}

export function toPercent(total: number, completed: number, fractionDigits: number = 0): number {
    const percent = total ? completed / total * hundred : 0;
    return toPercentImpl(percent, fractionDigits);
}

export function toPercentString(number: number, fractionDigits: number = 0): string {
    if (isNaN(number)) {
        return "0%";
    }

    const percent = number * hundred;
    return toPercentImpl(percent, fractionDigits) + "%";
}

const SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

export function abbreviateNumber(number: number): string {
    if (!number) {
        return "0";
    }
    const _3_digit = 3;
    const tier = Math.log10(number) / _3_digit | 0;
    if (tier < 0) {
        return Math.round(number).toString();
    }

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(ten, tier * _3_digit);
    const scaled = number / scale;
    return +(Math.round(+(scaled.toString() + "e+2")) + "e-2") + suffix;
}

export function numberWithSpaces(x: number): string {
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function arrayUnion<T extends { id: string }>(array1: T[], array2: T[]) {
    const array3 = array1.slice(0);
    const map = toDictionaryById(array1);
    let len = array2.length;
    while (len--) {
        const items = array2[len];
        if (!map[items.id]) {
            array3.push(items);
        }
    }

    return array3;
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function isBlank(str?: string) {
    return !str || /^\s*$/.test(str);
}

export function addOrReplace<T>(arr: T[], predicate: (element: T) => boolean, value: T) {
    const index = arr.findIndex(predicate);
    if (index === -1) {
        arr.push(value);
    } else {
        arr[index] = value;
    }
}

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

export function notBoolean<T>(x: T | boolean): x is T {
    return typeof x !== 'boolean';
}

export function distinct<T>(value: T, index: number, self: T[]): value is T {
    return self.indexOf(value) === index;
}

export function distinctByKey<T>(array: T[], key: keyof T) {
    return distinctBy(array, _ => _[key]);
}

export function distinctBy<T>(array: T[], getPropertyFunc: (item: T) => any): T[] {
    return array.reduce((accumulator: T[], current) => {
        if (!accumulator.some((_: any) => getPropertyFunc(_) === getPropertyFunc(current))) {
            accumulator.push(current);
        }
        return accumulator;
    }, []);
}

class Currency {
    private _code: string = 'USD';
    get code(): string {
        return this._code;
    }
    set code(value: string) {
        try {
            formatCurrency(1, value);
        }
        catch {
            return;
        }

        this._code = value;
    }
}

export function formatCurrency(value: number, curr: string) {
    return value.toLocaleString(undefined,
        {
            style: 'currency',
            currency: curr
        })
}

export const currency = new Currency();

class CultureInfo {
    private _name: string = 'en-US';
    get name(): string {
        return this._name;
    }
    set name(value: string) {
        try {
            new Date().toLocaleString(value);
        }
        catch {
            return;
        }

        this._name = value;
    }
}

export const cultureInfo = new CultureInfo();

export const debounceDelay: number = 500;
export function debounce<T>(timeDelay: number) {
    let timeout: any;
    return (value: T, callback: (value: T) => void) => {
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => { callback(value); }, timeDelay);
    };
}

export function removeEmptyProps(obj: { [key: string]: any }) {
    for (const propName in obj) {
        if (obj.hasOwnProperty(propName) && obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }

    return obj;
}

export const suppressMouseEventsBubble: {
    onMouseDown: MouseEventHandler<any>;
    onMouseUp: MouseEventHandler<any>;
    onClick: MouseEventHandler<any>;
} = {
    onMouseDown: (e) => { e.stopPropagation(); },
    onMouseUp: (e) => { e.stopPropagation(); },
    onClick: (e) => { e.stopPropagation(); }
};

export function copyToClipboard(copyText: string, onSuccess?: () => void): void {
    //https://github.com/OfficeDev/office-ui-fabric-react/blob/master/packages/experiments/src/components/SelectedItemsList/utils/copyToClipboard.ts
    const copyInput = document.createElement('input') as HTMLInputElement;
    copy(copyInput, copyText, onSuccess);
}

export function copyToClipboardWithFormatting(copyText: string, onSuccess?: () => void): void {
    // Create a textarea element to hold the text
    const textArea = document.createElement('textarea') as HTMLTextAreaElement;
    copy(textArea, copyText, onSuccess);
}

function copy(textInputElement: HTMLTextAreaElement | HTMLInputElement, copyText: string, onSuccess?: () => void): void {
    document.body.appendChild(textInputElement);

    try {
        textInputElement.value = copyText;
        textInputElement.select();
        if (!document.execCommand('copy')) {
            // The command failed. Fallback to the method below.
            throw new Error();
        }
        onSuccess?.();
    } catch (err) {
        // no op
    } finally {
        document.body.removeChild(textInputElement);
    }
}

function formatTime(totalSeconds: number): string {
    let result = "";
    if (totalSeconds === 0) {
        return "0h";
    }
    if (totalSeconds < 0) {
        totalSeconds = -1 * totalSeconds;
        result = "-";
    }

    const sec_per_hour = 3600;
    const halve_min = 30;
    const s = ~~totalSeconds % sec_per_min;
    let m = (totalSeconds % sec_per_hour - totalSeconds % sec_per_min) / sec_per_min;
    const h = (totalSeconds - totalSeconds % sec_per_hour) / sec_per_hour;

    if (h > 0) {
        result += h + "h ";
    }
    if (m > 0) {
        result += (s > halve_min ? ++m : m) + "m";
    }

    return result.trim();
}

function formatDays(totalDays: number): string {
    return `${totalDays}d`;
}

export function suppressEvent(handler?: (event: React.MouseEvent<any>) => void) {
    return (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        e.preventDefault();

        handler?.(e);
    }
}

export function getCookie(name: string): string | null {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const MinDateConst: Date = new Date(1970, 0, 1);
export const MaxDateConst: Date = new Date(2100, 11, 31, 23, 59, 59, 999);

export function toDate(dateObj?: MaybeDate): Date | undefined {
    if (dateObj instanceof Date) {
        return dateObj;
    }

    if (typeof dateObj === 'string') {
        const utcDate = new Date(dateObj);
        return new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    }

    return undefined;
}

export function toDateTime(dateObj?: MaybeDate): Date | undefined {
    if (dateObj instanceof Date) {
        return dateObj;
    }

    if (typeof dateObj === 'string') {
        return new Date(dateObj);
    }

    return undefined;
}

export function sortByDateTime(a: Date | string | null, b: Date | string | null, asc: boolean = true): number {
    const aDate = toDateTime(a)!;
    const bDate = toDateTime(b)!;
    return aDate === bDate ? 0
        : aDate < bDate
            ? (asc ? -1 : 1)
            : (asc ? 1 : -1);
}

export function sortNumber(a: number, b: number): number {
    return a - b;
}

export function upperFirstSort(a: string, b: string): number {
    return a.localeCompare(b, undefined, { caseFirst: "upper" });
}

export function sortByName(a: { name: string }, b: { name: string }): number {
    return a.name.localeCompare(b.name);
}

export function ensureHttpsSchema(url: string): string {
    if (url === "" || /^([\w\.\-]+\:\/\/)/i.test(url)) {
        return url;
    }
    if (/^([\w\.\-]+\:\/)/i.test(url)) {
        return "";
    }
    return "https://" + url;
}

export function getCheckUncheckLabel(checked: boolean) {
    return checked ? 'Uncheck to disable' : 'Check to enable';
};

export const HUNDRED_PCT = 100;
const NINETYNINE_PCT = 99;
const ZERO_PCT = 0;

export function roundToHundreds(value: number): number {
    return Math.round(HUNDRED_PCT * value) / HUNDRED_PCT;
}

export function adjustProgress(progress: number): number {
    if (progress >= HUNDRED_PCT) {
        return HUNDRED_PCT;
    }
    if (progress >= NINETYNINE_PCT) {
        return NINETYNINE_PCT;
    }
    if (progress < ZERO_PCT) {
        return ZERO_PCT;
    }
    return Math.round(progress);
}

export const stopEffects = (func?: (e: React.MouseEvent) => void): ((e: React.MouseEvent) => void) | undefined => func && (e => {
    e.stopPropagation();
    e.preventDefault();
    func(e);
});

export const groupByKey = <TKey extends string | number | symbol, TEntity>(
    array: TEntity[],
    key: keyof TEntity
): Record<TKey, TEntity[]> => {
    return groupBy(array, _ => _[key] as unknown as TKey);
};

export const groupBy = <TKey extends string | number | symbol, TEntity>(
    array: TEntity[],
    getKeyFunc: (item: TEntity) => TKey
): Record<TKey, TEntity[]> => {
    return array.reduce((hash, obj) => {
        const val = getKeyFunc(obj);
        if (val === undefined) {
            return hash;
        }
        return Object.assign(hash, { [val]: (hash[val] || []).concat(obj) });
    }, {} as Record<TKey, TEntity[]>);
};

export const splitArray = <T>(array: T[], callback: (element: T) => boolean): [T[], T[]] => {
    const matches: T[] = [];
    const nonMatches: T[] = [];
    array.forEach(element => (callback(element) ? matches : nonMatches).push(element));
    return [matches, nonMatches];
};

export const InfoMessages = {
    NotEnougthScope: "Account doesn't have enough scope permissions",
    GroupsBasicConnectionPermissions:
        "It is not possible to link to Office 365 Group using restricted connection. Please choose a regular connection or request full access permissions for this one.",
    OrganizationBasicConnectionPermissions:
        "It is not possible to link Office 365 Organization using restricted connection. Please choose a regular connection or request full access permissions for this one.",
    importPlansByAzureAdConnection: "It is not possible to import Plans using Azure Active Directory connection. "
        + "Please choose a restricted or regular connection or request full access permissions for this one.",
    linkProjectByAzureAdConnection: "It is not possible to link to Plan using Azure Active Directory connection. "
        + "Please choose a restricted or regular connection or request full access permissions for this one.",
    linkTEamsByAzureAdConnection: "It is not possible to link to Teams using Azure Active Directory connection. "
        + "Please choose a restricted or regular connection or request full access permissions for this one."
}

export const isLiteModel = (entity: ProjectInfo<any>): boolean => 
    entity.calculation.scheduleData == null 
    && entity.calculation.teamMembersData == null
    && entity.calculation.iterationsData == null;


export const IsEmptyObject = (obj: Object) => {
    if (typeof obj === "object") {
        return !obj || !Object.values(obj).length;
    }

    return false;
}

export const ClearObject = (obj: Object) => {
    for (const key in obj) {
        if (typeof obj[key] !== "function") {
            delete obj[key];
        }
    }
}